import React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import {
  Border,
  HelperText,
  Label,
  LabeledHelperTextProps
} from '@toasttab/buffet-pui-text-base'
import { t, loadStrings } from '../defaultStrings'

const defaultMaxCharsHelperTextFunc = (numChars: number, maxChars: number) => {
  loadStrings()
  const remainingChars = numChars <= maxChars ? maxChars - numChars : 0
  return (
    <span>{t('characters-remaining', { remainingChars: remainingChars })}</span>
  )
}

export interface TextareaProps
  extends LabeledHelperTextProps,
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'hidden'> {
  /** Apply a className to the root element. For example, adding 'mt-4' will add 16px of top margin; adding 'fs-unmask' will ummask the label, input, and helper text for full story. This should not be used to change the visual design of this component. */

  containerClassName?: string

  /** If true adds 'hidden' to container className to hide the component */
  hidden?: boolean

  /** Displays the characters remaining in place of the helper text. See also maxLength. */
  maxChars?: number

  /** A function that takes numChars and formats helper text; */
  maxCharsHelperTextFunc?: (
    numChars: number,
    maxChars: number
  ) => React.ReactNode
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    {
      name,
      label,
      onChange,
      onBlur,
      value,
      maxChars,
      maxCharsHelperTextFunc = defaultMaxCharsHelperTextFunc,
      containerClassName,
      hidden,
      className,
      disabled,
      testId,
      invalid,
      helperText,
      helperIconButton,
      errorText,
      preserveHelpSpace,
      required,
      id,
      ...restProps
    },
    ref
  ) {
    const uniqueTestId = useUniqueId(testId, 'textarea-')
    const uniqueId = useUniqueId(id, 'textarea-')
    const derivedHelperText = maxChars
      ? maxCharsHelperTextFunc(
          typeof value === 'string' ? value.length : 0,
          maxChars
        )
      : helperText

    return (
      <div
        className={cx(containerClassName, { hidden })}
        data-testid={`${uniqueTestId}-container`}
      >
        <Label
          name={uniqueId}
          disabled={disabled}
          helperIconButton={helperIconButton}
          required={required}
        >
          {label}
        </Label>
        <Border
          disabled={disabled}
          invalid={invalid}
          testId={uniqueTestId}
          label={label}
        >
          <textarea
            ref={ref}
            id={uniqueId}
            name={name}
            data-testid={uniqueTestId}
            className={cx(
              'rounded-input w-full outline-none bg-white',
              'type-default',
              disabled ? 'text-disabled' : 'text-default',
              disabled ? 'placeholder-25' : 'placeholder-75',
              {
                'cursor-default': disabled
              },
              'p-2.5',
              className
            )}
            disabled={disabled}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            aria-invalid={invalid}
            required={required}
            aria-required={required}
            {...restProps}
          />
        </Border>
        <HelperText
          testId={`${uniqueTestId}-helper-text`}
          helperText={derivedHelperText}
          disabled={disabled}
          errorText={errorText}
          invalid={invalid}
          preserveHelpSpace={preserveHelpSpace}
        />
      </div>
    )
  }
)

Textarea.displayName = 'Textarea'
