import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { GET_PARTNER_TILE_INFO } from '../../api/queries/getPartnerTileInfo'
import {
  buildDepotRequestContext,
  envStyle,
  getCurrentPartnerGuid
} from '@local/environment-switcher-utils'
import { ServerErrorPage } from '@local/depot-error-pages'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'

import './EditListingPage.css'
import { EditListingPageBody } from '../EditListingPageBody/EditListingPageBody'

export const EditListingPage = () => {
  const env = getCurrentEnvironment()
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()
  const {
    data: getPartnerTileInfo,
    loading: getPartnerTileInfoLoading,
    error: getPartnerTileInfoError,
    refetch
  } = useQuery<{ getPartnerInfo: PartnerTileInfo }>(GET_PARTNER_TILE_INFO, {
    context: buildDepotRequestContext(partner, mode ?? env),
    variables: { guid: getCurrentPartnerGuid(partner, mode ?? env) },
    fetchPolicy: 'no-cache'
  })
  refetch({ mode })

  const partnerTileInfo = useMemo(
    () => getPartnerTileInfo?.getPartnerInfo,
    [getPartnerTileInfo?.getPartnerInfo]
  )

  if (getPartnerTileInfoError) {
    return (
      <div className='bg-white'>
        <ServerErrorPage />
      </div>
    )
  }

  if (getPartnerTileInfoLoading) {
    return (
      <div className='flex flex-row justify-center w-full h-screen'>
        <MerryGoRound
          className='pin-center'
          size='sm'
          testId='credentials-loader'
        />
      </div>
    )
  }

  if (partnerTileInfo) {
    return (
      <div className={`${envStyle(mode ?? env)}`}>
        <EditListingPageBody partnerTileInfo={partnerTileInfo} />
      </div>
    )
  }

  return null
}
