import {
  HeadingGroup,
  Page,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { PropsWithChildren } from 'react'
import { envStyle } from '@local/environment-switcher-utils'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'

export interface LayoutProps {
  testId?: string | number
  subTitle?: string
  title?: string
}

/**
 * Layout component
 */
export function Layout({
  children,
  testId,
  subTitle,
  title
}: PropsWithChildren<LayoutProps>) {
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  return (
    <Page className={`h-full w-full ${envStyle(mode ?? env)}`} testId={testId}>
      <PageHeader className='grid place-items-stretch'>
        <HeadingGroup subTitle={subTitle}>
          <Title>{title}</Title>
        </HeadingGroup>
        <h1 className='type-headline-2 text-default text-opacity-10'>{mode}</h1>
      </PageHeader>
      <PageBody>{children}</PageBody>
    </Page>
  )
}
