import React, { useEffect, useState } from 'react'

const useActiveElement = () => {
  const [active, setActive] = React.useState(document.activeElement)

  const handleFocusIn = () => {
    setActive(document.activeElement)
  }

  React.useEffect(() => {
    document.addEventListener('focusin', handleFocusIn)
    return () => {
      document.removeEventListener('focusin', handleFocusIn)
    }
  }, [])

  return active
}

// this hook has a dependency on the focus-visible polyfill https://github.com/WICG/focus-visible
export function useIsCurrentElementFocusedByKeyboardNavigation(
  ref: React.RefObject<Element>
) {
  const [isFocused, setIsFocused] = useState(false)

  const focused = useActiveElement()
  useEffect(() => {
    setIsFocused(
      (ref &&
        ref.current === focused &&
        ref.current?.classList.contains('focus-visible')) ||
        false
    )
  }, [focused, ref])

  return isFocused
}
