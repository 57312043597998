import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const OrderingOnlineIllustration = React.forwardRef(
  function OrderingOnlineIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '400'}
              height={resizeToContainer ? '100%' : '187'}
              viewBox='0 0 400 187'
              fill='none'
              className='inline-block'
            >
              <path
                d='M234.63 60.775c2.082-.33 4.384-.44 6.904-.44h59.507s29.808 0 29.808 29.877h-96.767M271.233 45.398v13.62M271.233 54.185v13.62M234.63 149.854h96.22s-2.74 14.938-14.905 14.938h-80.876'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M234.959 134.916c6.466-.659 6.466-7.469 6.466-7.469v-14.938s0-7.469 7.452-7.469 7.452-7.469 7.452-7.469v-7.469'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M330.849 90.212v7.25s0 7.688-7.452 7.688h-7.452s-7.452.22-7.452-7.469c0 0 0 7.469-7.452 7.469h-52.055M236.274 134.916h20.164M241.425 120.088h7.452s7.452 0 7.452 7.469v14.828s0 7.469 7.452 7.469 7.452-7.469 7.452-7.469v-14.938s0-7.469 7.452-7.469h14.904s7.452 0 7.452 7.469v7.469s0 7.469 7.452 7.469 7.452-7.469 7.452-7.469v-7.469s0-7.469 7.452-7.469h7.452'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M271.233 134.916h7.452s7.452 0 7.452-7.469c0 0 0 7.469 7.452 7.469h7.452M330.849 134.916h-14.904'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M136.658 48.693v-26.91C136.658 11.457 145.096 3 155.397 3h60.494c10.301 0 18.739 8.458 18.739 18.782v143.889c0 10.325-8.438 18.783-18.739 18.783h-60.494c-10.301 0-18.739-8.458-18.739-18.783v-31.743'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M215.891 121.736h-55.453M215.891 134.367h-55.453M200.877 146.999h-40.439'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M0 165.012h400M135.89 22.771h97.973M99.068 165.012h111.343M164.384 62.093h49.972s0-19.66-19.616-19.66h-19.616s-12.932 0-17.754 10.544M165.808 99.438c2.521 1.209 5.589 1.978 9.315 1.978h19.617M194.74 81.754h-28.713'
                stroke='silver'
                strokeWidth={4}
              />
              <path
                d='M175.124 101.416h19.616c19.616 0 19.616-19.662 19.616-19.662h-39.232M205.15 62.093v19.661M175.124 42.432v9.776M194.74 42.432v9.776'
                stroke='silver'
                strokeWidth={4}
              />
              <path
                d='M79.89 48.693h64.548c11.945 0 21.589 9.666 21.589 21.638v26.252c0 11.972-9.644 21.638-21.589 21.638h-2.52v20.869l-21.37-20.869H79.89c-11.945 0-21.589-9.666-21.589-21.638V70.33c0-11.863 9.644-21.638 21.59-21.638zM75.178 71.759H120M136 71.759h11.616M97.425 94.496h50.192'
                stroke='#FF4C00'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
