import * as React from 'react'
import cx from 'classnames'
import {
  PageTargetNav,
  MobilePageTargetNav,
  PageTargetNavItem,
  PageTargetNavGroup,
  PageTargetNavGroupTitle
} from '@toasttab/buffet-pui-navigation'
import { ScrollerProvider } from '@toasttab/buffet-use-scroller'
import { FlushBreakpoint, useLayoutContext } from '../../common/LayoutProvider'

import { PageTargetNavFrame } from '../PageTargetNavFrame'
import { useOffsetTop } from '../useOffsetTop'

interface GroupedSections {
  [key: string]: string[]
}

type Sections = string[] | GroupedSections

interface PageTargetNavLayoutProps {
  testId?: string | number
  sections: Sections
  className?: string
  disableInitialScrollRestore?: boolean
}

export const flattenGroupedSections = (sections: Sections): string[] => {
  if (Array.isArray(sections)) {
    return sections
  }
  return Object.values(sections).reduce(
    (accumulator, current) => accumulator.concat(current),
    []
  )
}

interface NavItemsProps {
  items: string[]
  offset?: number
}

const NavItems = ({ items, offset }: NavItemsProps) => (
  <>
    {items.map((item) => (
      <PageTargetNavItem
        key={`page-nav-item-${item.replace(/\s/g, '-')}`}
        id={item}
        offset={offset}
      >
        {item}
      </PageTargetNavItem>
    ))}
  </>
)

interface GroupedNavItemsProps {
  sections: Sections
  offset?: number
  isMobile?: boolean
}

const GroupedNavItems = ({
  sections,
  offset,
  isMobile
}: GroupedNavItemsProps) => {
  if (Array.isArray(sections)) {
    return <NavItems items={sections} offset={offset} />
  } else {
    return (
      <>
        {Object.entries(sections).map(([sectionHeading, items], index) => (
          <PageTargetNavGroup
            key={`page-nav-group-${isMobile ? 'mobile-' : ''}${index}`}
          >
            <PageTargetNavGroupTitle>{sectionHeading}</PageTargetNavGroupTitle>
            <NavItems items={items} offset={offset} />
          </PageTargetNavGroup>
        ))}
      </>
    )
  }
}

export const PageTargetNavLayout: React.FC<
  React.PropsWithChildren<PageTargetNavLayoutProps>
> = ({
  testId = 'page-target-nav-layout',
  className = '',
  sections,
  children,
  disableInitialScrollRestore
}) => {
  const PageTargetNavOuterEl = React.useRef<HTMLDivElement>(null)
  const { pagePositions, scrollElRef, flushBreakpoint, setIsUsingLeftNav } =
    useLayoutContext()
  const flattenedSections = flattenGroupedSections(sections)

  const offsetTop = useOffsetTop()

  React.useEffect(() => setIsUsingLeftNav(true), [setIsUsingLeftNav])

  return (
    <div data-testid={testId} className={className} ref={PageTargetNavOuterEl}>
      <ScrollerProvider
        options={flattenedSections}
        offset={offsetTop}
        scrollElRef={scrollElRef}
        disableInitialScrollRestore={disableInitialScrollRestore}
      >
        <MobilePageTargetNav
          containerClassName={cx(
            'lg:hidden',
            flushBreakpoint === FlushBreakpoint.SM
              ? 'm-4 mt-0 sm:m-0'
              : 'm-4 mt-0 md:m-0'
          )}
          modalContainerClassName='lg:hidden'
          scrollElRef={scrollElRef}
        >
          <GroupedNavItems sections={sections} isMobile />
        </MobilePageTargetNav>
        <PageTargetNavFrame
          desktopNav={
            <PageTargetNav className='visible'>
              <GroupedNavItems
                sections={sections}
                offset={pagePositions.mainTop}
              />
            </PageTargetNav>
          }
        >
          {children}
        </PageTargetNavFrame>
      </ScrollerProvider>
    </div>
  )
}
