import * as React from 'react'
import { ScrollToContainer } from '@toasttab/buffet-use-scroller'
import { Panel, PanelProps } from '../../common'

interface ScrollPanelProps extends PanelProps {
  panelId: string
}

export const ScrollPanel: React.FC<
  React.PropsWithChildren<ScrollPanelProps>
> = ({ panelId, testId = 'scroll-panel', ...props }) => {
  return (
    <ScrollToContainer
      id={panelId}
      options={{ threshold: [0.001, 0.05, 0.1, 0.25, 0.5] }}
    >
      <Panel testId={testId} {...props} />
    </ScrollToContainer>
  )
}
