import { Link } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import {
  HeadingGroup,
  Page,
  PageBody,
  PageHeader,
  Panel,
  Title
} from '@toasttab/buffet-pui-config-templates'
import landingImage from '../../assets/landingImage.png'
import { envStyle } from '@local/environment-switcher-utils'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'

export interface MarketplaceListingLandingPageProps {
  testId?: string
}

export const MarketplaceListingLandingPage = ({
  testId = 'marketplace-landing-page'
}: MarketplaceListingLandingPageProps) => {
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  return (
    <Page className={`min-h-screen ${envStyle(mode ?? env)}`} testId={testId}>
      <PageHeader isUsingTabs className='block'>
        <HeadingGroup>
          <Title testId='listing-landing-title' className='pt-6 mb-4'>
            <div className='float-right'>
              <h1 className='type-headline-2 text-default text-opacity-10'>
                {mode}
              </h1>
            </div>
            Toast Partner Integrations marketplace listing
            <div
              data-testid='listing-landing-subtitle'
              className='type-default text-secondary'
            >
              Manage the appearance and details of your listing on the Toast
              Partner Integrations Marketplace
            </div>
          </Title>
        </HeadingGroup>
      </PageHeader>
      <PageBody>
        <Panel
          className='h-96 mt-8 flex flex-col justify-around items-start'
          style={{
            backgroundImage: `url(${landingImage})`,
            backgroundSize: `contain`,
            backgroundRepeat: `no-repeat`,
            backgroundPositionX: `right`
          }}
        >
          <div>
            <h4 className='type-headline-4 mb-4'>Your listing is live</h4>
            <p className='type-default text-secondary'>
              Your integration listing is visible on Toast Partner Integrations
              marketplace.
            </p>
          </div>
          <Button
            testId='edit-details'
            variant={Button.Variant?.primary}
            iconLeft={<EditIcon accessibility='decorative' />}
            as={Link}
            to={`/marketplace-listing/edit`}
          >
            Edit details
          </Button>
        </Panel>
      </PageBody>
    </Page>
  )
}
