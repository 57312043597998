import * as React from 'react'
import cx from 'classnames'

import {
  useCurrentScrollerValue,
  useScrollerRawState
} from '@toasttab/buffet-use-scroller'
import { PageTargetNavProvider } from '../PageTargetNavContext'
import { useMobilePageTargetNav } from './useMobilePageTargetNav'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { FullScreenMenuInPortal } from '../../FullScreenMenu'
import { MobilePageTargetNavButton } from './MobilePageTargetNavButton'

export interface MobilePageTargetNavProps
  extends Omit<ActiveCategoryProps, 'title'> {}

export const MobilePageTargetNav: React.FunctionComponent<
  React.PropsWithChildren<MobilePageTargetNavProps>
> = (props) => {
  const { value } = useCurrentScrollerValue()
  const { optionStringLookup } = useScrollerRawState()
  return (
    <ActiveCategory title={optionStringLookup[value] || value} {...props} />
  )
}

export interface ActiveCategoryProps extends TestIdentifiable {
  /**
   * The class name for the overall container of the mobile nav component.
   */
  containerClassName?: string
  /**
   * The class name for the container responsible for the modal content containing nav items.
   */
  modalContainerClassName?: string
  /**
   * DOM element which will be responsible for hosting the portal content of the `FullScreenMenuInPortal`. Default is `document.getElementById('root')`
   */
  portalEl?: HTMLElement | null
  /**
   * The content of the button trigger for the modal. Can be a string or Node.
   */
  title: string
  /**
   * Callback to notify when the modal is opened or closed.
   */
  onOpenStateChange?: (isOpen: boolean) => void
  /*
   * The scroll element for scrolling back to the top on mobile
   */
  scrollElRef?: React.RefObject<Element>
}

export const ActiveCategory: React.FC<
  React.PropsWithChildren<ActiveCategoryProps>
> = ({
  containerClassName,
  modalContainerClassName,
  children,
  title,
  portalEl,
  onOpenStateChange,
  scrollElRef
}) => {
  const {
    isOpen,
    setOpen,
    setClosed,
    triggerRef,
    focusedIndex,
    setFocusedIndex,
    count,
    domContext,
    onKeyDown
  } = useMobilePageTargetNav<Record<string, unknown>, HTMLButtonElement>(
    onOpenStateChange
  )

  return (
    <PageTargetNavProvider
      value={{
        onClickPageTargetNavItem: setClosed,
        focusedIndex,
        setFocusedIndex,
        count,
        domContext,
        onKeyDown
      }}
    >
      <div className={cx(containerClassName)}>
        {!isOpen && (
          <MobilePageTargetNavButton
            onClick={setOpen}
            triggerRef={triggerRef}
            scrollElRef={scrollElRef}
          >
            {title}
          </MobilePageTargetNavButton>
        )}
        {isOpen && (
          <FullScreenMenuInPortal
            setClosed={setClosed}
            portalEl={portalEl}
            containerClassName={modalContainerClassName}
          >
            {children}
          </FullScreenMenuInPortal>
        )}
      </div>
    </PageTargetNavProvider>
  )
}
