import * as React from 'react'
import { useRect } from '@reach/rect'
import { usePageTargetNavContext } from '../PageTargetNavContext'
import {
  DescendantContext,
  useDescendant
} from '../PageTargetNavContext/useDescendants'
import { normalizeEventKey } from '../PageTargetNavContext/normalizeEventKey'
import { useIsCurrentElementFocusedByKeyboardNavigation } from './useActiveElement'

export function useRailItem<
  E extends Element = Element,
  C extends Record<string, unknown> = Record<string, unknown>
>(props: {
  onClick?: () => void
}): {
  ref: React.RefObject<E>
  containerRef: React.RefObject<E>
  isFocusVisible: boolean
  onClick: () => void
  domContext: DescendantContext<C>
  index: number
  isLast: boolean
  rect: DOMRect | null
  onKeyDown: (event: React.KeyboardEvent) => void
} {
  const ref = React.useRef<E>()
  const containerRef = React.useRef<E>()

  const { onClickPageTargetNavItem, domContext, count, onKeyDown } =
    usePageTargetNavContext()

  const index = useDescendant({ context: domContext, element: ref.current })
  const rect = useRect(containerRef)
  const isFocusVisible = useIsCurrentElementFocusedByKeyboardNavigation(
    ref as React.RefObject<E>
  )

  function onClick() {
    if (onClickPageTargetNavItem) onClickPageTargetNavItem()
    if (props.onClick) props.onClick()
  }

  function onKeyDownRailItem(event: React.KeyboardEvent): void {
    onKeyDown?.(event) //eslint-disable-line
    const eventKey = normalizeEventKey(event)
    const keyMap: Record<string, () => void> = {
      Enter: onClick,
      ' ': onClick
    }
    const action = keyMap[eventKey]

    if (action) {
      event.preventDefault()
      action()
    }
  }

  return {
    ref: ref as React.RefObject<E>,
    containerRef: containerRef as React.RefObject<E>,
    isFocusVisible,
    onClick,
    domContext,
    index,
    isLast: index === count - 1,
    rect,
    onKeyDown: onKeyDownRailItem
  }
}
