import { ToastEnvironment } from '@toasttab/current-environment'

export enum CredentialsSections {
  Credentials = 'Credentials',
  Scopes = 'Scopes',
  Endpoints = 'API access endpoint'
}

export const SCOPE_SUBTITLE = `Scopes are mechanisms to let an application request limited access to data. The below scope denote which data can be accessed by this application.`

export const DYNAMIC_ENDPOINT_SUBTITLE: { [env: string]: string } = {
  preprod: `Toast's Preproduction environment is a stable, production-like environment that you can use to test your own changes against without worrying about affecting real data in production.`,
  sandbox: `Toast's Sandbox environment is a stable, production-like environment that you can use to test your own changes against without worrying about affecting real data in production.`,
  prod: `Toast's Production environment should only be used after testing and confirming your changes are working as expected. Using this endpoint will affect real data in production.`
}

export const DYNAMIC_ENDPOINT_URL: { [env: string]: string } = {
  preprod: `https://ws-preproduction-api.eng.toasttab.com`,
  sandbox: `https://ws-sandbox-api.eng.toasttab.com`,
  prod: `https://ws-api.toasttab.com`
}

export const getActiveEnvFromLabel = (envLabel: string) => {
  switch (envLabel) {
    case 'PREPROD (MAIN)':
    case 'PREPROD (SECONDARY)':
      return ToastEnvironment.PREPROD
    case 'SANDBOX':
      return ToastEnvironment.SANDBOX
    case 'PRODUCTION':
      return ToastEnvironment.PROD
    default:
      return envLabel
  }
}
