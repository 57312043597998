import { useMutation } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useBanquetProps, useSentry } from 'banquet-runtime-modules'
import { useCallback } from 'react'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { PUT_PARTNER_TILE_INFO } from '../mutations/updatePartnerTileInfo'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { buildDepotRequestContext } from '@local/environment-switcher-utils'

export function useUpdatePartnerTile(onSaved?: () => void) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const env = getCurrentEnvironment()
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()

  const { hub } = useSentry()

  const [updatePartnerTile, { loading: saving }] = useMutation<{
    updatePartner: PartnerTileInfo
  }>(PUT_PARTNER_TILE_INFO, {
    context: buildDepotRequestContext(partner, mode ?? env),
    onCompleted: () => {
      if (onSaved) {
        onSaved()
      } else {
        showSuccessSnackBar(`Your Listing was successfully updated`)
      }
    }
  })

  const updatePartnerTileInfo = useCallback(
    async (newPartnerTile: PartnerTileInfo) => {
      newPartnerTile?.categories?.forEach((cat) => delete cat['__typename'])
      delete newPartnerTile['__typename']
      try {
        await updatePartnerTile({
          variables: { newPartner: newPartnerTile }
        })
      } catch (error) {
        hub.captureException(error)
        showErrorSnackBar(
          'Oops! There has been an error updating your listing. Please try again.',
          {
            testId: 'saving-error-snackbar'
          }
        )
        throw error
      }
    },
    [hub, showErrorSnackBar, updatePartnerTile]
  )

  return {
    saving,
    updatePartnerTileInfo
  }
}
