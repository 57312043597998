import React from 'react'
import cx from 'classnames'
import { useAnchorLinkNavItemProps } from '@toasttab/buffet-use-scroller'
import { useRailItem } from './useRailItem'

import style from './PageTargetNavItem.module.css'

const styles = {
  baseStyle: {
    container:
      'relative hover:no-underline flex flex-1 items-center cursor-pointer text-secondary',
    innerContainer: 'px-2 py-2 hover:bg-darken-4 rounded -ml-px w-full',
    innerContainerFocused: 'focus-within:shadow-focus',
    link: 'no-underline outline-none type-default',
    inactive: {
      container: '',
      link: 'text-secondary font-normal'
    },
    active: {
      container: '',
      link: 'text-brand-100 font-bold'
    }
  }
}

export interface RailItemProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  onClick?: () => void
  className?: string
  isActive?: boolean
}

export const RailItem: React.FC<React.PropsWithChildren<RailItemProps>> = ({
  children,
  className,
  isActive,
  ...props
}) => {
  const { ref, onClick, isFocusVisible, onKeyDown, rect, containerRef } =
    useRailItem<HTMLAnchorElement>(props)

  return (
    <div className={cx('flex', style['rail-item'])}>
      <div className={cx('relative w-2')}>
        <div
          className={`absolute ${isActive ? 'bg-brand-50' : 'bg-gray-30'}`}
          style={{
            width: '3px',
            height: `${(rect?.height || 40) - 14}px`,
            left: 0,
            top: '7px'
          }}
        />
        <div
          className={cx(
            'bg-gray-30 absolute left-0',
            style['rail-item-connector']
          )}
          style={{
            width: '3px',
            height: '14px',
            bottom: '-7px'
          }}
        />
      </div>
      <div
        className={cx(styles.baseStyle.container, {
          [styles.baseStyle.active.container]: isActive,
          [styles.baseStyle.inactive.container]: !isActive
        })}
        onClick={onClick}
      >
        <div
          className={cx(styles.baseStyle.innerContainer, {
            [styles.baseStyle.innerContainerFocused]: isFocusVisible
          })}
          ref={containerRef as unknown as React.RefObject<HTMLDivElement>}
        >
          <a
            ref={ref}
            className={cx(
              styles.baseStyle.link,
              {
                [styles.baseStyle.active.link]: isActive,
                [styles.baseStyle.inactive.link]: !isActive
              },
              className
            )}
            onKeyDown={onKeyDown}
            role='menuitem'
            {...props}
          >
            {children}
          </a>
        </div>
      </div>
    </div>
  )
}

export interface PageTargetNavItemProps extends RailItemProps {
  /**
   * _Required_, used for the `useScroller` component, to understand what part of the page to scroll to.
   */
  id: string
  /**
   * Optional top offset (in pixels) to pass to `useAnchorLinkNavItemProps`
   */
  offset?: number
  /**
   * Used to determine if a `PageTargetNavItem` should apply the active styles or not.
   */
  isActive?: boolean
}

export const PageTargetNavItem: React.FC<
  React.PropsWithChildren<PageTargetNavItemProps>
> = ({ id, offset, ...props }) => {
  const { spreadProps, selected } = useAnchorLinkNavItemProps<typeof RailItem>(
    id,
    offset
  )
  return (
    <RailItem
      {...spreadProps({
        isActive: selected,
        'data-testid': `page-target-nav-item-${id}`,
        role: 'menuitem',
        tabIndex: -1,
        ...(selected && {
          tabIndex: 0,
          'data-testid': 'page-target-nav-item-active-item',
          'aria-current': true
        }),
        ...props
      })}
    >
      {props.children}
    </RailItem>
  )
}
