import * as React from 'react'
import { useFormikContext } from 'formik'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { Button, ButtonProps } from '@toasttab/buffet-pui-buttons'

export interface SubmitButtonProps extends ButtonProps {
  /** The `form` you wish to submit when this button is clicked - must match the form `id`  */
  form?: ButtonProps['form']
  /** Set this to true if the form is currently being submitted (or if waiting for response from endpoint) */
  isInProgress?: boolean
}

export const SubmitButton = ({
  children = 'Submit',
  disabled,
  className,
  form,
  isInProgress,
  ...props
}: SubmitButtonProps) => {
  const formik = useFormikContext()
  if (!formik) {
    throw new Error(
      'Submit button should only be used within the context of a Formik form'
    )
  }
  return (
    <Button
      disabled={
        disabled || formik.isSubmitting || formik.isValidating || isInProgress
      }
      form={form}
      className={className}
      type='submit'
      iconLeft={
        isInProgress ? (
          <AutorenewIcon className='animate-spin' accessibility='decorative' />
        ) : null
      }
      {...props}
    >
      {children}
    </Button>
  )
}
