import { gql } from '@apollo/client'

export const PARTNER_FIELDS = gql`
  fragment PartnerFields on PartnerTileInfo {
    additionalFee
    adminRemoveOnly
    applicationName
    availableForToastNow
    categories {
      guid
      label
      description
    }
    channel
    clientId
    connectRequired
    countries
    createdBy
    createdDate
    deleted
    deletedDate
    description
    featureId
    guid
    iconUrl
    infoUrl
    isThirdPartyOrderPartner
    mainUrl
    marketImageUrl
    modifiedBy
    modifiedDate
    name
    needsAccountVerification
    onboardingEmail
    originalIconUrl
    partnerType
    partnerBehaviour
    preferred
    primaryAction
    primaryActionReference
    primaryCustomCtaLabel
    requiredFeatures
    secondaryAction
    secondaryActionReference
    secondaryCustomCtaLabel
    signUpUrl
    supportEmail
    supportPhone
    version
    visible
  }
`

export const GET_PARTNER_TILE_INFO = gql`
  ${PARTNER_FIELDS}
  query getPartnerInfo($guid: ID!) {
    getPartnerInfo(guid: $guid) {
      ...PartnerFields
    }
  }
`
