import * as React from 'react'
import cx from 'classnames'
import { useLayoutContext } from '../../common'
import { TwoColumnLayout } from '../TwoColumnLayout'

interface PageTargetNavFrameProps {
  className?: string
  desktopNav: React.ReactNode
}

export const PageTargetNavFrame: React.FC<
  React.PropsWithChildren<PageTargetNavFrameProps>
> = ({ className = '', desktopNav, children }) => {
  const PageTargetNavEl = React.useRef<HTMLDivElement>(null)
  const { pagePositions } = useLayoutContext()

  return (
    <div className={cx('min-h-screen', className)}>
      <div className='mt-6 mb-16'>
        <TwoColumnLayout
          leftColumn={
            <nav
              className='sticky lg:pl-4'
              ref={PageTargetNavEl}
              style={{
                top: pagePositions.mainTop ? pagePositions.mainTop : 'auto'
              }}
            >
              {desktopNav}
            </nav>
          }
        >
          {children}
        </TwoColumnLayout>
      </div>
    </div>
  )
}
