import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { envStyle } from '@local/environment-switcher-utils'

import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'

export function ServerErrorPage() {
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  return (
    <div className={`${envStyle(mode ?? env)}`}>
      <ErrorPage500 />
    </div>
  )
}
