import * as React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { useKey } from 'react-use'
import cx from 'classnames'
import {
  useFloating,
  PortalManagement
} from '@toasttab/buffet-pui-floating-ui-base'
import { t, loadStrings } from './defaultStrings'

interface FullScreenCategoryMenuProps {
  setClosed: () => void
  containerClassName?: string
  children?: React.ReactNode
  isSecondaryNav?: boolean
}

interface FullScreenMenuInPortalProps extends FullScreenCategoryMenuProps {
  portalEl?: HTMLElement | null
  isSecondaryNav?: boolean
}

export const FullScreenMenuInPortal: React.FC<
  React.PropsWithChildren<FullScreenMenuInPortalProps>
> = ({ portalEl, ...props }) => {
  const { context } = useFloating()

  return (
    <PortalManagement context={context} portalContainerEl={portalEl}>
      <FullScreenCategoryMenu {...props} />
    </PortalManagement>
  )
}

export const FullScreenCategoryMenu: React.FC<
  React.PropsWithChildren<FullScreenCategoryMenuProps>
> = ({ setClosed, containerClassName, children, isSecondaryNav }) => {
  useKey('Escape', setClosed)
  loadStrings()
  return (
    <div
      className={cx(
        // elevation: this menu acts as a full screen modal which uses z-40 according to our guidelines
        'fixed top-0 left-0 right-0 bottom-0 z-40 h-full w-full bg-white overflow-y-scroll',
        containerClassName
      )}
    >
      <div className='flex justify-end p-6 pb-1'>
        <IconButton
          cropToIcon
          iconColor='secondary'
          icon={<CloseIcon aria-label={t('close-navigation')} />}
          onClick={setClosed}
        />
      </div>
      {isSecondaryNav ? (
        <div className={cx('px-5')}>{children}</div>
      ) : (
        <nav className={cx('px-5')} role='menu'>
          {children}
        </nav>
      )}
    </div>
  )
}
