import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const PayrollMobileIllustration = React.forwardRef(
  function PayrollMobileIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '376'}
              height={resizeToContainer ? '100%' : '144'}
              viewBox='0 0 376 144'
              fill='none'
              className='inline-block'
            >
              <path
                d='M286.934 93.556s0 4.98 4.979 4.98c4.98 0 4.98 4.98 4.98 4.98M296.893 93.556s0 4.98 4.979 4.98c4.98 0 4.98 4.98 4.98 4.98M306.852 93.556s0 4.98 4.979 4.98c4.98 0 4.98 4.482 4.98 4.482'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M316.811 132.73s9.959.414 9.959-12.212c0-12.627-9.959-12.023-9.959-12.023v-4.979h-25.002v29.151'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M316.811 128.413v-14.938s4.979 0 4.979 4.979v4.98M193.181 105.958v-17.04M167.921 123.244c0 3.208 1.412 9.624 9.624 9.624M163.411 111.09c-.962.642-1.796 1.797-3.849 5.133-2.054 3.337-5.775 8.876-7.058 10.907'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M163.09 111.411c2.458-1.925 11.357-5.453 28.551-5.453l34.326 7.057m-5.454-1.121l9.624-24.222'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M203.543 64.254c-15.11 5.132-20.531 9.624-22.777 14.756l-11.003 29.01M237.388 65.5h27.075c7.956 0 15.732-.605 13.794 10.623-1.604 9.303-8.238 40.849-12.832 57.423M177.847 126.168h2.887c5.646 0 8.553-6.523 9.304-9.303.955-3.566.898-10.302-1.669-12.869-3.208-3.208-8.661-.962-9.944 2.246'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M241.301 65.5c14.757 0 17.002 12.832 11.87 25.985l-13.41 29.871M207.681 78.653v30.155M216.92 70.312v40.778M255.417 82.503v49.724M317.595 132.731H166.088M375.8 132.96H.8'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M88.921 127.772h38.176'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M199.291 75.829c0-1.565-.097-4.554 4.491-4.554M226.223 132.868l-41.062-7.699'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M168.525 130.659c-.308-2.299-3.914-2.887-5.755-2.887h-35.609c-4.921-11.762-15.142-36.25-16.682-40.1-1.54-3.85-4.491-4.598-5.774-4.491H76.154c-1.925-.107-5.39.706-3.85 4.812 1.54 4.106 13.686 31.83 19.569 44.875h77.89l-1.238-2.209zm0 0c.012.104.019 1.283.019 1.394l-.019-1.394z'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M226.456 59.006c-1.881 4.553-7.112 6.174-9.536 6.174v5.132M.8 141.565h375'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M232.865 42.713c1.689 1.397 4.858 3.293 7.595.85 3.441-3.068-.217-7.613-.217-7.613s5.878 1.007 5.878-4.053-5.563-4.933-5.563-4.933 6.928-1.124 5.58-6.37c-1.348-5.245-7.645-2.895-7.645-2.895s4.336-4.348 1.027-7.602c-3.309-3.254-7.146-.15-7.146-.15a4.673 4.673 0 00-3.011-5.669c-4.204-1.726-6.01 3.132-6.01 3.132s-5.162-4.632-11.528-4.632c-6.366 0-11.413 4.632-11.413 4.632s-2.976-5.147-6.578-2.831c-3.602 2.316-1.795 7.064-1.795 7.064s-3.567-5.147-7.685-2.061c-4.118 3.086.126 7.73.126 7.73s-7.008-.794-7.008 4.77c0 5.565 5.419 4.882 5.419 4.882s-5.167.932-4.668 5.923c.573 5.541 8.086 3.926 8.086 3.926s-4.76.428-3.756 5.413c.803 3.943 6.498 2.502 6.498 2.502'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M205.08 40.833a4.29 4.29 0 01-3.046-1.274 4.367 4.367 0 01-1.262-3.075M239.824 65.5c-12.923 0-13.636-8.84-13.636-8.84V40.833a4.287 4.287 0 003.046-1.274 4.377 4.377 0 000-6.15 4.29 4.29 0 00-3.046-1.274h-8.616s-4.307-.104-4.307-4.35V23.54M211.541 57.369c-5.384 0-11.108-3.936-12.923-8.699M188.942 30.57s4.588-.11 4.588-4.631v-4.522h4.588s4.588-.04 4.588-4.742'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M62.137 61.26s0 12.292 12.291 12.292c0 0-12.29 0-12.29 12.29 0 0 0-12.29-12.292-12.29 0 0 12.291 0 12.291-12.291zM121.37 112.909l19.648-6.926c1.137-.401 1.795-1.471 1.471-2.391l-3.522-9.991c-.324-.92-1.508-1.34-2.645-.94l-20.15 7.103'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M129.471 106.035a3.28 3.28 0 002.18-2.911 3.269 3.269 0 00-3.733-3.415 3.271 3.271 0 00-.948 6.191 3.274 3.274 0 002.501.135zM149.502 74.45L127.886 71.5c-1.194-.163-2.269.488-2.401 1.454l-1.434 10.497c-.132.966.729 1.881 1.923 2.044l21.616 2.953c1.194.163 2.269-.488 2.401-1.455l1.433-10.496c.132-.967-.729-1.882-1.922-2.045z'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M137.893 83.242a3.27 3.27 0 003.02-4.06 3.27 3.27 0 00-2.69-2.446 3.274 3.274 0 00-2.687 5.658 3.27 3.27 0 002.357.847v0z'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
