// Useful type guards live here...

/**
 * Infers the result string of the typeof operator
 * to a type.
 */
export type InferTypeOf<T> = T extends 'string'
  ? string
  : T extends 'number'
  ? number
  : T extends 'function'
  ? Function
  : T extends 'object'
  ? object
  : T extends 'undefined'
  ? undefined
  : never

export const isTypeOf = <T extends string>(
  type: T,
  value: unknown
): value is InferTypeOf<T> => typeof value === type
export const isString = (v: unknown): v is string => isTypeOf('string', v)
export const isNumber = (v: unknown): v is number => isTypeOf('number', v)
export const isFinite = (v: unknown): v is number =>
  isNumber(v) && v !== Infinity && v !== -Infinity && !Number.isNaN(v)
export const isFunction = <T extends Function>(v: unknown): v is T =>
  isTypeOf('function', v)
export const isObject = <T extends object>(v: unknown): v is T =>
  (isTypeOf('object', v) || isFunction(v)) && v !== null
export const isUndefined = (v: unknown): v is undefined =>
  isTypeOf('undefined', v)
export const isPromiseLike = <T>(v: unknown): v is Promise<T> =>
  isObject<{ then?: Function }>(v) && isFunction(v.then)
export const isNil = (v: unknown): v is null | undefined => v == null
export const isNotNil = <T>(v: T | null | undefined): v is NonNullable<T> =>
  !isNil(v)
