import * as React from 'react'
import cx from 'classnames'
import {
  Formats,
  DateRange,
  createFormatRange,
  addMonths,
  subMonths,
  setDay,
  isSameMonth
} from '@toasttab/buffet-pui-date-utilities'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import {
  Calendar,
  RangeCalendarProps,
  useCalendarNumberOfMonths,
  getDynamicRangeValue
} from '@toasttab/buffet-pui-calendar'
import {
  DatePickerContainer,
  DayPickerContainerBaseProps
} from '../DatePickerContainer'
import { DefaultStaticRange } from '../DatePickerWithDefinedRanges'
import { useDeprecationWarning, useUniqueId } from '@toasttab/buffet-utils'
import { defaultRanges } from '../DatePickerWithDefinedRanges/presets'
import {
  DatePickerContextProvider,
  OnSelectEventType
} from '../useDatePickerContext'
import { useCalendarConstraints } from '../useCalendarConstraints/useCalendarConstraints'
import { loadStrings, t } from '../defaultStrings'

export interface DateRangePickerProps
  extends Omit<DayPickerContainerBaseProps<DateRange>, 'formatValue'>,
    Omit<
      RangeCalendarProps,
      'selected' | 'disabled' | 'mode' | 'onChange' | 'onSelect'
    > {
  value?: DateRange
  formatValue?: string | ((value: DateRange) => string | null | undefined)
  disabled?: boolean
  showDefinedRanges?: boolean
  showDateStepperButtons?: boolean
  definedRanges?: DefaultStaticRange[]
  // We redefine our own type for onChange and onSelect so that we can pass `undefined` as a value for formik
  // (see `onValueChangeHandler` in DatePickerContainer)
  onChange?: OnSelectEventType<DateRange>
  /** @deprecated OnSelect will be removed in the future, use onChange instead */
  onSelect?: OnSelectEventType<DateRange>
}

export const DateRangePicker = ({
  testId = 'DateRangePicker',
  containerClassName,
  disabled,
  invalid,
  required,
  errorText,
  helperText,
  helperIconButton,
  name,
  id,
  value,
  label,
  'aria-label': ariaLabel,
  preserveHelpSpace,
  placeholder,
  size = 'auto',
  placement = 'bottom-start',
  inlineBlock,
  formatValue = Formats.date.medium, // 'MMM d'
  locale: localeOverride,
  showDefinedRanges = false,
  showDateStepperButtons = false,
  definedRanges: _definedRanges,
  modalParentSelector, // eslint-disable-line
  numberOfMonths,
  onOpenChange: _onOpenChange,
  ...calendarProps
}: DateRangePickerProps) => {
  const definedRanges = React.useMemo(
    () => _definedRanges ?? defaultRanges(calendarProps.weekStartsOn),
    [_definedRanges, calendarProps.weekStartsOn]
  )
  const uniqueId = useUniqueId(id, 'date-range-picker-')
  const locale = localeOverride || getLocale()
  const [month, setMonth] = React.useState<Date>(value?.from ?? new Date())
  const actualNumberOfMonths = useCalendarNumberOfMonths(numberOfMonths)
  const { minDate, maxDate } = useCalendarConstraints(calendarProps)

  loadStrings()
  const translatedPlaceholder = placeholder ?? t('select-dates')

  const containerProps = {
    testId,
    containerClassName,
    placement,
    disabled,
    invalid,
    required,
    inlineBlock,
    placeholder: translatedPlaceholder,
    size,
    name,
    id: uniqueId,
    value,
    label,
    ariaLabel,
    errorText,
    helperText,
    helperIconButton,
    preserveHelpSpace,
    showDateStepperButtons,
    locale,
    minDate,
    maxDate
  }

  useDeprecationWarning(calendarProps, 'onSelect', 'onChange')
  const onChange: OnSelectEventType<DateRange> = React.useCallback(
    (newValue, selectedDay, modifiers, e) => {
      const valueToUse = getDynamicRangeValue(
        value,
        newValue,
        selectedDay,
        required
      )
      // prefer to use onChange but still support the now deprecated onSelect
      const onChangeProp = calendarProps.onChange ?? calendarProps.onSelect // eslint-disable-line deprecation/deprecation
      onChangeProp?.(valueToUse, selectedDay, modifiers, e)
    },
    [calendarProps.onChange, calendarProps.onSelect, required, value] // eslint-disable-line deprecation/deprecation
  )

  const onCustomDateChange = React.useCallback(
    (name: string, newDate: Date) => {
      if (actualNumberOfMonths === 1) {
        setMonth(newDate)
        return
      }
      const start = setDay(month, 1)
      const end = addMonths(start, actualNumberOfMonths)
      //we only want to move calendar if user manually entered a date outside the visible calendar range
      if (newDate < start || newDate > end) {
        setMonth(
          name === 'to' ? subMonths(newDate, actualNumberOfMonths - 1) : newDate
        )
      }
    },
    [month, actualNumberOfMonths]
  )

  return (
    <DatePickerContextProvider<DateRange>
      locale={locale}
      onSelect={onChange}
      modalParentSelector={modalParentSelector}
      value={value}
      definedRanges={definedRanges}
      showDefinedRanges={showDefinedRanges}
    >
      <DatePickerContainer<DateRange>
        formatValue={createFormatRangeFunction(formatValue, locale)}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            // When we open make sure to open on the correct month
            // The value could be anything at this point
            const expectedMonth = value?.from ?? new Date()
            if (!isSameMonth(month, expectedMonth)) {
              setMonth(expectedMonth)
            }
          }
          _onOpenChange?.(isOpen)
        }}
        onCustomDateChange={onCustomDateChange}
        {...containerProps}
        renderCalendar={(dateRange, _, { onSelect }) => (
          <Calendar
            defaultMonth={dateRange?.from}
            {...calendarProps}
            mode='range'
            initialFocus
            selected={dateRange}
            month={month}
            onMonthChange={setMonth}
            numberOfMonths={numberOfMonths}
            onChange={onSelect}
            locale={locale}
            containerClassName={cx(showDefinedRanges && 'mx-auto')}
            required={required}
          />
        )}
      />
    </DatePickerContextProvider>
  )
}

const createFormatRangeFunction = (
  formatStrOrFn: string | ((value: DateRange) => string | null | undefined),
  locale: Locale
) => {
  return typeof formatStrOrFn === 'string'
    ? createFormatRange(formatStrOrFn, locale)
    : formatStrOrFn
}
