import * as React from 'react'

export const TwoColumnLayout = ({
  leftColumn,
  children
}: {
  leftColumn: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <div className='lg:flex gap-4'>
      <div
        className='hidden lg:block lg:w-64 flex-shrink-0'
        // This column should remain 25% of the width
        // even if some other left nav (or right nav!) is present on the page
        style={{ flexBasis: '25%', maxWidth: '16rem' }}
      >
        {leftColumn}
      </div>
      <div
        className='w-full min-w-0'
        // This needs to be 100%, not 75%, for when the maxWidth of the left column is reached
        style={{ flexBasis: '100%' }}
      >
        {children}
      </div>
    </div>
  )
}
