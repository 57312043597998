import * as React from 'react'
import {
  BaseMobileNavButton,
  BaseMobileNavButtonProps
} from '../../BaseMobileNavButton'

export const MobilePageTargetNavButton = ({
  children,
  testId = 'mobile-page-target-nav-expand',
  ...props
}: React.PropsWithChildren<
  Omit<BaseMobileNavButtonProps, 'hasScrollReturn'>
>) => (
  <BaseMobileNavButton testId={testId} hasScrollReturn {...props}>
    {children}
  </BaseMobileNavButton>
)
