import React, { useCallback, ComponentProps, MouseEvent } from 'react'
import { sanitizeScrollerId } from './utils'
import {
  useSelectElementInView,
  useScrollerState,
  useScrollToAnchorCallback,
  SelectionStrategy
} from './Scroller'

type ComponentToSpread =
  | keyof JSX.IntrinsicElements
  | React.JSXElementConstructor<any>

// #region useAnchorLinkNavItemProps Types

type UseAnchorLinkNavItemSpreadPropsReturnValue<
  P extends ComponentToSpread,
  E,
  Args = Partial<ComponentProps<P>>
> = {
  onClick: (e: MouseEvent<E>) => void
  onKeyPress: () => void
  'data-href': string
  'aria-current': boolean
  'aria-label': string
  selected: boolean
} & Args

interface UseAnchorLinkNavItemSpreadPropsFn<P extends ComponentToSpread, E> {
  <UsedComponentProps extends ComponentProps<P>>(
    args?: UsedComponentProps
  ): UseAnchorLinkNavItemSpreadPropsReturnValue<P, E, UsedComponentProps>
}

interface UseAnchorLinkNavItemProps<P extends ComponentToSpread, E> {
  selected: boolean
  spreadProps: UseAnchorLinkNavItemSpreadPropsFn<P, E>
}

// #endregion

/**
 * creates a set of props to spread onto a nav item.
 * It connects the nav item into the srcoller state.
 *
 * Passing a optional selectionStrategy function allows you to override the
 * NavItem selection. It has the following function signature:
 * multiSelectionStrategy(id, status)
 * @param id
 * @param selectionStrategy(id, status) changes how the item gets selected.
 */
export const useAnchorLinkNavItemProps = <
  P extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
  E extends HTMLElement = HTMLElement
>(
  id: string,
  offset?: number,
  selectionStrategy?: SelectionStrategy
): UseAnchorLinkNavItemProps<P, E> => {
  const safeId = sanitizeScrollerId(id)
  const onClickAnchor = useScrollToAnchorCallback({ href: safeId, offset })
  const { selected } = useScrollerState(safeId, selectionStrategy)
  const spreadProps: UseAnchorLinkNavItemSpreadPropsFn<P, E> = useCallback(
    (props) => {
      return {
        ...(props ?? ({} as ComponentProps<P>)),
        onKeyPress: () => onClickAnchor(),
        'data-href': `#${safeId}`,
        'aria-current': selected,
        selected: selected,
        'aria-label': `scroll to ${id}`,
        onClick: (e: MouseEvent<E>) => {
          onClickAnchor()
          props?.onClick?.(e)
        }
      }
    },
    [id, onClickAnchor, selected, safeId]
  )
  return { selected, spreadProps }
}

// #region useAnchorElementProps Types

interface UseAnchorElementSpreadPropsFn<P extends ComponentToSpread> {
  <UsedComponentProps extends ComponentProps<P>>(args?: UsedComponentProps): {
    id: string
    ref: ReturnType<typeof useSelectElementInView>['ref']
    'aria-label': string
  } & Omit<UsedComponentProps, 'ref' | 'id' | 'aria-label'>
}

interface UseAnchorElementProps<P extends ComponentToSpread> {
  selected: boolean
  spreadProps: UseAnchorElementSpreadPropsFn<P>
}

// #endregion

/**
 * props to spread onto the element you wish to link to
 */
export const useAnchorElementProps = <P extends ComponentToSpread>(
  id: string,
  options?: Parameters<typeof useSelectElementInView>[1]
): UseAnchorElementProps<P> => {
  const safeId = sanitizeScrollerId(id)
  const { ref } = useSelectElementInView(safeId, options)
  const { selected } = useScrollerState(safeId)
  const spreadProps: UseAnchorElementSpreadPropsFn<P> = useCallback(
    (props) => ({
      ...(props ?? ({} as ComponentProps<P>)),
      id: safeId,
      ref,
      role: 'region',
      'aria-label': `${id} content`
    }),
    [id, ref, safeId]
  )
  return { selected, spreadProps }
}

/**
 * Sets a height to a wrapper element so that the bottom item
 * has space to be selected.
 */
export const useAddBottomMarginProps = (
  reduceOffset: number = 100
): {
  lastItemRef: React.MutableRefObject<{ offsetHeight: number }>
  spreadProps: { style: { marginBottom: string } }
} => {
  // This functionality is designed for larger form scrolling at a page level
  const [height, setHeight] = React.useState(0)
  const lastItemRef = React.useRef({
    offsetHeight: 0
  })
  React.useEffect(() => {
    setHeight(
      window.innerHeight - (lastItemRef.current.offsetHeight + reduceOffset)
    )
  }, [reduceOffset])
  const spreadProps = { style: { marginBottom: `${height}px` } }
  return { lastItemRef, spreadProps }
}
