import { Partner } from '@toasttab/depot-banquet-props'

export const SWITCHER_AVAILABLE_ENVS: { [key: string]: string[] } = {
  dev: ['TEST (MAIN)', 'TEST (SECONDARY)'],
  preprod: ['PREPROD (MAIN)', 'PREPROD (SECONDARY)'],
  prod: ['PRODUCTION', 'SANDBOX']
}

export function isSecondaryEnv(environment: string): boolean {
  return Object.values(SWITCHER_AVAILABLE_ENVS).some(
    (envList) => envList[1] === environment
  )
}

export const envStyle = (activeEnv: string) =>
  isSecondaryEnv(activeEnv)
    ? 'bg-gradient-to-b from-gray-0 to-warning-0'
    : 'bg-gray-0'

export const buildDepotRequestContext = (
  partner: Partner,
  activeEnvironment: string
) => ({
  headers: {
    'toast-organization-guid': getCurrentPartnerGuid(
      partner,
      activeEnvironment
    ),
    'active-environment': activeEnvironment
  }
})

export function getCurrentPartnerGuid(
  partner: Partner,
  activeEnvironment: string
): string | null {
  return isSecondaryEnv(activeEnvironment) ? partner.crossEnvGuid : partner.guid
}

export const getBaseUrlBasedOnEnv = (activeEnv: string) => {
  switch (activeEnv) {
    case 'PREPROD (MAIN)':
    case 'PREPROD (SECONDARY)':
      return 'https://developer.preprod.eng.toasttab.com'
    case 'SANDBOX':
      return 'https://developer.sandbox.eng.toasttab.com'
    case 'PRODUCTION':
      return 'https://developer.toasttab.com'
    case 'TEST (MAIN)':
    case 'TEST (SECONDARY)':
      return 'https://www.base.toasttab.com'
    default:
      // switcher not enabled, using actual environment which needs no explicit (absolute) base url setup
      return ''
  }
}
