import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { Layout } from '@local/layout'
import { ConnectedRestaurants } from '../ConnectedRestaurants/ConnectedRestaurants'

export const ConnectedRestaurantsPage = () => {
  const { partner } = useDepotBanquetProps()

  return (
    <Layout title='Connected Restaurants' subTitle={partner.name}>
      <ConnectedRestaurants partner={partner} />
    </Layout>
  )
}
