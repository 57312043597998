import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ExportIcon = React.forwardRef(function ExportIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M17.28 10.5L21 14.3m0 0l-3.72 3.8M21 14.3H9.034c-.604 0-1.006-.4-1.006-1V8.2m8.547-1.9V5c0-1.1-.905-2-2.01-2H5.01C3.905 3 3 3.9 3 5v14c0 1.1.905 2 2.011 2h9.553'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
