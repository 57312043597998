import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const TabletTeamIllustration = React.forwardRef(
  function TabletTeamIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '400'}
              height={resizeToContainer ? '100%' : '272'}
              viewBox='0 0 400 272'
              fill='none'
              className='inline-block'
            >
              <path
                d='M290.848 102.948s4.109 5.291 9.367 2.804c5.74-2.712 4.068-9.459 4.068-9.459s.241 6.58 6.58 6.58 7.363-5.98 7.363-5.98-2.172 6.272 3.419 8.959c5.59 2.687 9.325-2.904 9.325-2.904M329.19 149.534a21.626 21.626 0 01-39.931 0M284.209 137.888a6.655 6.655 0 110-13.31M309.224 139.552a6.654 6.654 0 01-6.655-6.655M337.451 137.888a6.657 6.657 0 006.655-6.655 6.655 6.655 0 00-6.655-6.655'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M295.199 158.311a7.361 7.361 0 01-9.551.965c-5.582-3.502-2.562-10.424-2.562-10.424s-6.722 4.434-10.756-.723c-4.035-5.158.208-10.333.208-10.333s-7.437 1.664-9.151-4.758c-1.714-6.422 3.993-8.319 3.993-8.319s-9.351.832-8.044-8.394c.973-6.563 10.165-5.058 10.165-5.058s-6.314-3.527-4.159-9.3c2.154-5.774 9.334-3.719 9.334-3.719s-4.01-6.48.599-10.232c4.608-3.752 10.964-.516 10.964-.516s-3.327-6.838 1.664-9.816c4.991-2.979 9.833 1.447 9.833 1.447S298.709 73 304.008 73c5.915 0 6.847 6.713 6.847 6.713.507-3.194 1.664-6.713 6.53-6.713 4.867 0 6.581 6.098 6.581 6.098s4.858-4.426 9.833-1.448c4.974 2.978 1.663 9.817 1.663 9.817s6.356-3.228 10.965.516c4.609 3.743.599 10.232.599 10.232s7.162-2.055 9.342 3.718c2.18 5.774-4.159 9.301-4.159 9.301s9.15-1.664 10.132 5.091c1.356 9.151-8.044 8.394-8.044 8.394s5.681 1.913 3.993 8.319c-1.689 6.406-9.151 4.758-9.151 4.758s4.242 5.175.208 10.333c-4.035 5.157-10.757.723-10.757.723s3.028 6.922-2.562 10.424-9.75-1.248-9.75-1.248'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M325.862 159.517v36.604s0 13.31-13.31 13.31c-16.38 0-16.638-13.31-16.638-13.31v-36.604s-21.205 5.945-32.905 19.525M325.862 159.517s38.575 13.211 43.259 36.604c5.582 27.91 9.983 69.879 9.983 69.879M295.914 196.121s1.369 25.035-24.154 26.549M325.862 196.121s1.872 26.62 29.949 26.62M355.702 217.85l.009 48.15M295.914 162.845s-.732 16.638 13.31 16.638M120.23 142.878a21.532 21.532 0 01-39.752 0M73.853 129.627a6.627 6.627 0 01-6.625-6.625 6.627 6.627 0 016.625-6.625M100.354 129.627a6.627 6.627 0 01-6.625-6.625M126.855 129.627a6.628 6.628 0 004.685-1.94 6.628 6.628 0 000-9.37 6.628 6.628 0 00-4.685-1.94M87.103 103.126h26.502s13.25 0 13.25 13.251V96.501s0-13.25-13.25-13.25c0 0-6.626 0-6.626-6.626 0-6.625-6.625-6.625-6.625-6.625h-13.25s-13.251 0-13.251 13.25v6.626s0 13.25 13.25 13.25zM95.534 266v-13.971h45.404'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M116.445 150.058v43.064s0 16.563-16.563 16.563-16.563-19.875-16.563-19.875v-29.814s-46.99 8.182-52.1 31.47C25.141 219.251 21 266 21 266M116.445 159.996s11.828 2.03 24.325 6.952M83.319 189.81s-1.035 34.269-31.677 34.269M51.658 214.762V266M116.445 189.81s.786 16.267 11.297 26.383M83.319 159.996s2.898 17.491 16.869 17.491'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M225.203 136.631a21.889 21.889 0 01-32.343 9.793 21.88 21.88 0 01-8.062-9.793M205 123.163a6.734 6.734 0 01-6.734-6.734M231.937 109.695a6.734 6.734 0 110 13.468v27.989s0 10.101 10.101 10.101'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M167.962 161.253c10.101 0 10.101-10.101 10.101-10.101v-27.989a6.734 6.734 0 010-13.468c10.102 0 13.469-20.203 13.469-20.203a50.509 50.509 0 0040.405 20.203V96.226s0-26.936-26.937-26.936-26.937 26.936-26.937 26.936'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M178.063 96.226v-66.29S178.063 3 205 3s26.937 30.304 26.937 30.304V93.91M191.532 26.57v33.67M218.468 26.57v33.67M205 26.57v30.303M198.266 231.962H205M198.266 258.899H205M181.43 269v-41.625M157.861 269v-57.24M252.139 269v-57.24M221.836 142.263v25.724s0 6.735-6.735 6.735v16.835s13.469-3.367 13.469-16.835'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M221.836 157.886s39.883 3.594 47.139 33.671L275.709 269M188.165 157.886s-6.11.551-13.982 3.024M151.202 264.726s-24.579-25-24.579-62.5c0-34.375 25-28.125 25-28.125'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M139.123 189.726s3.125-9.375 15.625-9.375c0 0 6.25 15.625-9.375 31.25'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M198.498 170.976l15.625 46.875s3.125 9.375-9.375 9.375h-31.25s-9.375 0-12.5-9.375l-3.125-9.375M192.248 161.601h-43.75s-12.5 0-6.25 12.5'
                stroke='#FF4C00'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M151.623 174.101s-9.375-3.125-18.75 6.25c-9.374 8.594-5.468 35.156-5.468 35.156'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path d='M400 269H0' stroke='silver' strokeWidth={4} />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
