import { useState, useRef, useCallback, useEffect } from 'react'

export function useUnmountEffect(fn: () => void): void {
  return useEffect(() => () => fn(), [fn])
}

export function useForceUpdate() {
  const unloadingRef = useRef(false)
  const [count, setCount] = useState(0)

  useUnmountEffect(() => {
    unloadingRef.current = true
  })

  return useCallback(() => {
    if (!unloadingRef.current) {
      setCount(count + 1)
    }
  }, [count])
}
