import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const QuestionAnswerDisplayIcon = React.forwardRef(
  function QuestionAnswerDisplayIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={64}
              height={64}
              viewBox='0 0 64 64'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'xl'].className
              )}
            >
              <path
                d='M38.75 25.75h15.2c3.8 0 6.8 3.1 6.8 6.8v6.9c0 3.8-3.1 6.8-6.8 6.8h-8.7l-6.5 6.4V46.25c-3.8 0-6.8-3.1-6.8-6.8v-6.9c0-3.7 3-6.8 6.8-6.8z'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.55 23.35v-1.5h.8c1.3 0 2.4-1.6 2.4-3.1 0-1.3-1.3-2.4-2.8-2.4-1.1 0-2.1.8-2.4 1.8'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.55 28.65a1.4 1.4 0 100-2.8 1.4 1.4 0 000 2.8z'
                fill='#FF4C00'
              />
              <path
                d='M25.25 11.35h-15.2c-3.8 0-6.8 3.1-6.8 6.8v6.9c0 3.8 3.1 6.8 6.8 6.8h8.7l6.5 6.4V31.85c3.8 0 6.8-3.1 6.8-6.8v-6.9c0-3.7-3-6.8-6.8-6.8z'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M39.55 33.05h13.6M39.55 39.05h13.6'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeMiterlimit={10}
                strokeLinecap='round'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
