import * as React from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import { useWindowSize } from '@toasttab/buffet-use-window-size'
import { useLayoutContext } from '../common/LayoutProvider'

/**
 * Returns the offsetTop and has a side-effect of setting this
 * page position in the 'mainTop' key
 */
export const useOffsetTop = () => {
  const { width: windowWidth } = useWindowSize()

  const offsetTop = React.useRef(0)
  const { setPagePositions, headerRef, scrollElRef } = useLayoutContext()

  const { size: headerRect, initialOffsetTop } = useHeaderMetrics(
    headerRef,
    scrollElRef
  )
  const headerRectHeight = headerRect?.height

  React.useLayoutEffect(() => {
    if (headerRectHeight !== undefined) {
      offsetTop.current = headerRectHeight + initialOffsetTop + 24
      setPagePositions('mainTop', offsetTop.current)
    }
  }, [windowWidth, setPagePositions, headerRectHeight, initialOffsetTop])

  return offsetTop.current
}

function useHeaderMetrics<T extends HTMLElement>(
  target?: React.RefObject<T>,
  scrollElRef?: React.RefObject<Element>
) {
  const [size, setSize] = React.useState<DOMRect>()
  const [initialOffsetTop, setInitialOffsetTop] = React.useState<number>(0)

  React.useLayoutEffect(() => {
    const rect = target?.current?.getBoundingClientRect()
    setSize(rect)
    if (scrollElRef) {
      const scrollTop = scrollElRef?.current?.scrollTop || 0
      const offsetTop = target?.current?.offsetTop || 0
      setInitialOffsetTop(offsetTop - scrollTop)
    } else if (rect) {
      setInitialOffsetTop(rect?.top)
    }
  }, [target, scrollElRef])

  const reactToResize = (
    setSize: React.Dispatch<React.SetStateAction<DOMRect | undefined>>,
    target?: React.RefObject<T>,
    size?: DOMRect
  ) => {
    const newSize = target?.current?.getBoundingClientRect()
    if (newSize?.height !== size?.height) {
      setSize(newSize)
      // The resize observer doesn't trigger often enough
      // So we'll keep checking on each animation frame
      // until we get a stable header height
      window.requestAnimationFrame(() => {
        reactToResize(setSize, target, newSize)
      })
    }
  }

  // Where the magic happens
  useResizeObserver(target || null, () => {
    reactToResize(setSize, target, size)
  })
  return { size, initialOffsetTop }
}
