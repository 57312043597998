import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const EmailDisplayIcon = React.forwardRef(function EmailDisplayIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={64}
            height={64}
            viewBox='0 0 64 64'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'xl'].className
            )}
          >
            <path
              d='M56 21.7l-24 14-24-14'
              stroke='#FF4C00'
              strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M52 49H12c-2.2 0-4-1.8-4-4V19c0-2.2 1.8-4 4-4h40c2.2 0 4 1.8 4 4v26c0 2.2-1.7 4-4 4z'
              stroke='#5069C3'
              strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
