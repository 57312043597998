// All credit and inspiration taken from https://github.com/chakra-ui/chakra-ui/blob/develop/packages/descendant/src/use-descendant.ts

import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useForceUpdate } from './useForceUpdate'

type Descendant<T extends Record<string, unknown> = Record<string, unknown>> = {
  element: Element | undefined
} & T

export interface DescendantContext<
  T extends Record<string, unknown> = Record<string, unknown>
> {
  descendants: Descendant[]
  register: (args: Descendant<T>) => void
  unregister: (element: Element) => void
}

export function useDescendant(props: {
  context: DescendantContext
  element: Descendant['element'] | undefined
  index?: number
  disabled?: boolean
  focusable?: boolean
}): number {
  const { context, element, index: indexProp, disabled, focusable } = props

  const forceUpdate = useForceUpdate()
  const { register, unregister, descendants } = context

  useLayoutEffect(() => {
    if (!element) {
      forceUpdate()
    }

    /**
     * Don't register this descendant if it is disabled and not focusable
     */
    if (disabled && !focusable) return undefined

    /**
     * else, register the descendant
     */
    register({ element, disabled, focusable })

    /**
     * when it unmounts, unregister the descendant
     */
    return () => {
      if (element) {
        unregister(element)
      }
    }
  }, [element, disabled, focusable, forceUpdate, register, unregister])

  const index =
    indexProp ??
    descendants.findIndex((descendant) => descendant.element === element)

  return index
}

export function useDescendants<
  T extends Record<string, unknown> = Record<string, unknown>
>(): DescendantContext<T> {
  const [descendants, setDescendants] = useState<Descendant<T>[]>([])

  const register = useCallback(({ element, ...rest }: Descendant<T>) => {
    if (!element) return

    setDescendants((prevDescendants: Descendant<T>[]) => {
      if (!prevDescendants.some((item) => item.element === element)) {
        const index = prevDescendants.findIndex((item) => {
          if (!item.element || !element) return false

          return Boolean(
            item.element.compareDocumentPosition(element) &
              Node.DOCUMENT_POSITION_PRECEDING
          )
        })

        const newItem = { element, ...rest } as Descendant<T>

        if (index === -1) {
          return [...prevDescendants, newItem]
        }
        return [
          ...prevDescendants.slice(0, index),
          newItem,
          ...prevDescendants.slice(index)
        ]
      }
      return prevDescendants
    })
  }, [])

  const unregister = useCallback((element: Element) => {
    if (!element) return
    setDescendants((descendants) =>
      descendants.filter((descendant) => element !== descendant.element)
    )
  }, [])

  return useMemo(
    () => ({ descendants, register, unregister }),
    [descendants, register, unregister]
  )
}
