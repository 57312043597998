import * as React from 'react'
import cx from 'classnames'

export interface PageTargetNavGroupProps
  extends React.HTMLAttributes<HTMLElement> {
  className?: string
  testId?: string
}

export const PageTargetNavGroup: React.FC<
  React.PropsWithChildren<PageTargetNavGroupProps>
> = ({ testId = 'PageTargetNavGroup', className, children, ...props }) => (
  <div
    className={cx('mt-4 first:mt-2', className)}
    data-testid={testId}
    role='group'
    {...props}
  >
    {children}
  </div>
)
