import {
  createContext,
  useCallback,
  useContext,
  useState,
  KeyboardEvent
} from 'react'
import { normalizeEventKey } from './normalizeEventKey'
import { DescendantContext, useDescendants } from './useDescendants'

const PageTargetNavContext = createContext<{
  domContext: DescendantContext
  focusedIndex: number
  count: number
  setFocusedIndex: (index: number) => void
  onClickPageTargetNavItem?: () => void
  onKeyDown?: (event: KeyboardEvent) => void
}>({
  focusedIndex: -1,
  count: -1,
  setFocusedIndex: () => {},
  domContext: {
    register: () => {},
    unregister: () => {},
    descendants: []
  }
})

export const PageTargetNavProvider = PageTargetNavContext.Provider

export function usePageTargetNav<
  T extends Record<string, unknown> = Record<string, unknown>
>(): {
  focusedIndex: number
  setFocusedIndex: (index: number) => void
  count: number
  domContext: DescendantContext<T>
  onKeyDown: (event: KeyboardEvent) => void
} {
  const [focusedIndex, setFocusedIndex] = useState(0)
  const domContext = useDescendants<T>()
  const count = domContext.descendants.length

  const setIndex = useCallback(
    (index: number) => {
      const tab = domContext.descendants[index]
      if (tab?.element) {
        ;(tab.element as HTMLInputElement).focus?.() //eslint-disable-line
        setFocusedIndex(index)
      }
    },
    [domContext.descendants, setFocusedIndex]
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const nextTab = () => setIndex((focusedIndex + 1) % count)
      const prevTab = () => setIndex((focusedIndex - 1 + count) % count)
      const firstTab = () => setIndex(0)
      const lastTab = () => setIndex(count - 1)

      const eventKey = normalizeEventKey(event)
      const keyMap: Record<string, (() => void) | undefined> = {
        ArrowDown: nextTab,
        ArrowUp: prevTab,
        Home: firstTab,
        End: lastTab
      }

      const action = keyMap[eventKey]

      if (action) {
        event.preventDefault()
        action()
      }
    },
    [count, focusedIndex, setIndex]
  )

  return { focusedIndex, setFocusedIndex, count, domContext, onKeyDown }
}

export function usePageTargetNavContext() {
  return useContext(PageTargetNavContext)
}
