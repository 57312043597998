import * as React from 'react'
import { useField } from 'formik'
import { Textarea, TextareaProps } from '@toasttab/buffet-pui-text-area'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { FormValuesWithName, TypedName } from '../commonTypes'

export interface TextareaFieldProps<
  FormValues extends FormValuesWithName = string
> extends RemoveFields<TextareaProps, 'name'>,
    TypedName<FormValues> {}

export const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  TextareaFieldProps
>((props, ref) => {
  const { onChange, ...restProps } = props
  const [field, meta, helpers] = useField(restProps)
  const errorText = meta.error
  const invalid = meta.touched && !!errorText

  return (
    <Textarea
      ref={ref}
      invalid={invalid}
      errorText={errorText}
      {...field}
      {...restProps}
      onChange={(e) => {
        helpers.setTouched(true)
        helpers.setValue(e.target.value)
        onChange?.(e)
      }}
    />
  )
}) as <FormValues extends FormValuesWithName = string>(
  props: TextareaFieldProps<FormValues> & {
    ref?: React.ForwardedRef<HTMLInputElement>
  }
) => JSX.Element
