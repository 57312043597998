type ScrollableElement = Window | Element

const isHtmlElement = (T: ScrollableElement): T is HTMLElement => {
  return !!(T as Element)?.scrollTop
}

export const scrollToPosition = (
  px: number = 0,
  offset: number = 0,
  animate: boolean = true,
  scrollEl: ScrollableElement = window
): void => {
  if (!scrollEl) {
    scrollEl = window
  }

  const scrollTop = isHtmlElement(scrollEl)
    ? scrollEl.scrollTop
    : window.scrollY

  const adjustedScrollToPos =
    px + scrollTop - offset - ((scrollEl as HTMLElement).offsetTop || 0)

  if ('scrollBehavior' in document.documentElement.style) {
    scrollEl.scrollTo({
      top: adjustedScrollToPos,
      behavior: animate ? 'smooth' : 'auto'
    })
  } else {
    scrollEl.scrollTo(0, adjustedScrollToPos)
  }
}
