import { useEffect, useRef, useState } from 'react'
import { scrollToPosition } from './scrollToPosition'
import { ScrollerState } from './Scroller'

export function useScrollRestorationForInitialId(
  state: ScrollerState,
  offset: number = 0,
  animate: boolean = true,
  scrollEl?: Element | null,
  disableInitialScrollRestore?: boolean
): void {
  const hash = window.location.hash
  const initialEntry = useRef<string | undefined>()

  if (!initialEntry.current) {
    initialEntry.current = decodeURI(hash || '#top').slice(1)
  }
  const [isRestored, setIsRestored] = useState(false)

  useEffect(() => {
    if (!initialEntry.current) {
      return
    }
    const element = state.entries[initialEntry.current]
    if (
      !isRestored &&
      element?.boundingClientRect &&
      !disableInitialScrollRestore
    ) {
      scrollToPosition(
        element.boundingClientRect.top,
        offset,
        animate,
        scrollEl ?? window
      )
      setIsRestored(true)
    }
  }, [
    state,
    isRestored,
    animate,
    offset,
    scrollEl,
    disableInitialScrollRestore
  ])
}
