import * as React from 'react'
import { PhotoIcon } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import { t, loadStrings } from '../defaultStrings'

export type FitType = 'fill' | 'contain' | 'cover'
export type PositionType =
  | 'left-top'
  | 'left'
  | 'left-bottom'
  | 'top'
  | 'center'
  | 'bottom'
  | 'right-top'
  | 'right'
  | 'right-bottom'

type ImageState = 'LOADING' | 'LOADED' | 'ERROR'

const LOADING_STATE: ImageState = 'LOADING'
const LOADED_STATE: ImageState = 'LOADED'
const ERROR_STATE: ImageState = 'ERROR'

interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> {
  src: string
  alt: string
  containerClassName?: string
  onLoaded?: () => void
  onError?: () => void
  showError?: boolean
  errorComp?: JSX.Element
  testId?: string
  fit?: FitType
  position?: PositionType
}

interface LoadedProps {
  src: string
  hasError: boolean
}

const INITIAL_LOADED: LoadedProps = {
  src: '',
  hasError: false
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      src,
      alt,
      containerClassName,
      className,
      onLoaded,
      onError,
      showError,
      errorComp,
      testId = 'img-comp',
      fit,
      position,
      ...props
    },
    ref
  ) => {
    const [state, setState] = React.useState<ImageState>(LOADING_STATE)
    const [loaded, setLoaded] = React.useState<LoadedProps>(INITIAL_LOADED)

    const setLoadedState = (src: string, hasError: boolean) => {
      setLoaded({ src, hasError })
    }

    /*
      We are setting all the state values in the useEffect, as there is a race
      condition with useEffect and onImageLoad when the browser caches images.
      This also clearly covers a case where the component updates with a falsy
      src prop.
    */
    React.useEffect(() => {
      if (src === loaded.src) {
        setState(loaded.hasError ? ERROR_STATE : LOADED_STATE)
      } else if (src) {
        setState(LOADING_STATE)
      } else {
        setLoadedState(src, true)
      }
    }, [src, loaded])

    const onImageLoad = () => {
      if (onLoaded) {
        onLoaded()
      }
      setLoadedState(src, false)
    }

    const onImageError = () => {
      if (onError) {
        onError()
      }
      setLoadedState(src, true)
    }

    loadStrings()

    const badState = !src || state === ERROR_STATE
    const isLoading = state === LOADING_STATE

    if (badState && !showError) {
      return null
    }

    return (
      <div
        data-testid={`${testId}-${isLoading ? 'loading' : 'settled'}`}
        className={cx(
          'overflow-hidden align-middle relative',
          containerClassName,
          {
            'skeleton-base': isLoading,
            'inline-block': !fit && !position,
            'inline-flex': fit || position
          }
        )}
      >
        {isLoading && (
          <span className='absolute inset-0 bg-gradient-to-r from-transparent via-darken-4 to-transparent animate-wave-to-r'></span>
        )}
        {badState ? (
          errorComp || <DefaultImageError title={alt} testId={testId} />
        ) : (
          <img
            {...props}
            ref={ref}
            src={src}
            alt={alt}
            onLoad={onImageLoad}
            onError={onImageError}
            className={cx(
              className,
              getFitClass(fit),
              getPositionClass(position),
              {
                'opacity-50 transition-opacity ease-in duration-200': isLoading,
                'h-full w-full': fit || position
              }
            )}
            data-testid={`${testId}-img`}
          />
        )}
      </div>
    )
  }
)

interface DefaultImageErrorProps {
  title: string
  testId: string
}

const DefaultImageError = ({ title, testId }: DefaultImageErrorProps) => {
  return (
    <div
      className='w-full h-full flex items-center justify-center bg-gray-30 text-secondary'
      title={title}
      data-testid={`${testId}-default`}
    >
      <PhotoIcon size='md' aria-label={t('photo')} />
    </div>
  )
}

const getFitClass = (fit?: FitType) => {
  switch (fit) {
    case 'fill':
      return 'object-fill'
    case 'contain':
      return 'object-contain'
    case 'cover':
      return 'object-cover'
    default:
      return ''
  }
}

const getPositionClass = (position?: PositionType) => {
  switch (position) {
    case 'left-top':
      return 'object-left-top'
    case 'left':
      return 'object-left'
    case 'left-bottom':
      return 'object-left-bottom'
    case 'top':
      return 'object-top'
    case 'center':
      return 'object-center'
    case 'bottom':
      return 'object-bottom'
    case 'right-top':
      return 'object-right-top'
    case 'right':
      return 'object-right'
    case 'right-bottom':
      return 'object-right-bottom'
    default:
      return ''
  }
}
