import { useEffect, useRef, useState, KeyboardEvent, RefObject } from 'react'
import { usePageTargetNav } from '../PageTargetNavContext'
import { normalizeEventKey } from '../PageTargetNavContext/normalizeEventKey'
import { DescendantContext } from '../PageTargetNavContext/useDescendants'

export function useMobilePageTargetNav<
  T extends Record<string, unknown> = Record<string, unknown>,
  E extends Element = Element
>(
  onOpenStateChange: (isOpen: boolean) => void = () => {}
): {
  isOpen: boolean
  setOpen: () => void
  setClosed: () => void
  focusedIndex: number
  setFocusedIndex: (index: number) => void
  count: number
  triggerRef: RefObject<E>
  domContext: DescendantContext<T>
  onKeyDown: (event: KeyboardEvent) => void
} {
  const triggerRef = useRef<E>()
  const [isOpen, setIsOpen] = useState(false)

  function setOpen() {
    setIsOpen(true)
    onOpenStateChange(true)
  }

  function setClosed() {
    setIsOpen(false)
    onOpenStateChange(false)
  }

  const { focusedIndex, setFocusedIndex, count, domContext, onKeyDown } =
    usePageTargetNav()

  useEffect(() => {
    if (isOpen && domContext.descendants.length > 0) {
      ;(domContext.descendants[focusedIndex].element as HTMLInputElement).focus?.() //eslint-disable-line
    } else if (!isOpen && triggerRef) {
      ;(triggerRef.current as any).focus?.() //eslint-disable-line
    }
  }, [isOpen, domContext, focusedIndex])

  function onKeyDownMobilePageTargetNav(event: KeyboardEvent): void {
    onKeyDown(event)
    const eventKey = normalizeEventKey(event)
    const keyMap: Record<string, () => void> = {
      Escape: () => isOpen && setClosed()
    }
    const action = keyMap[eventKey]

    if (action) {
      event.preventDefault()
      action()
    }
  }

  return {
    isOpen,
    setOpen,
    setClosed,
    focusedIndex,
    setFocusedIndex,
    count,
    domContext,
    triggerRef: triggerRef as RefObject<E>,
    onKeyDown: onKeyDownMobilePageTargetNav
  }
}
