import 'intersection-observer'
import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  createContext,
  useReducer,
  useMemo,
  Dispatch,
  ContextType,
  useLayoutEffect
} from 'react'
import { InViewHookResponse, useInView } from 'react-intersection-observer'
import throttle from 'lodash/throttle'
import { isUndefined } from '@toasttab/buffet-utils'
import { useScrollRestorationForInitialId } from './useScrollRestorationForInitialId'
import { scrollToPosition } from './scrollToPosition'
import { sanitizeScrollerId } from './utils'

export interface ScrollerState {
  options: string[]
  optionStringLookup: Record<string, string>
  offset: number
  scrollElRef?: React.RefObject<Element>
  status: Record<string, boolean>
  entries: Record<string, IntersectionObserverEntry | undefined>
}

export type ScrollerId = keyof ScrollerState['status']
export type ScrollerStatus = ScrollerState['status']['']
export type ScrollerOptions = ScrollerState['options']

const TOP_OFFSET = 0

export const getDefaultState = () => ({
  options: [],
  optionStringLookup: {},
  offset: TOP_OFFSET,
  scrollElRef: undefined,
  status: {},
  entries: {}
})
const ScrollerStateContext = createContext<ScrollerState>(getDefaultState())
const ScrollerDispatchContext = createContext<Dispatch<Actions>>(() => {})

export const UPDATE_SELECTION = 'update-selection'
export const UPDATE_PARAMS = 'update-params'

/**
 * Action to select the current item.
 */
export class UpdateSelection {
  readonly type: typeof UPDATE_SELECTION
  readonly payload: {
    id: ScrollerId
    status: ScrollerStatus
    entry: IntersectionObserverEntry | undefined
  }

  constructor(
    id: ScrollerId,
    status: ScrollerStatus,
    entry?: IntersectionObserverEntry
  ) {
    this.type = UPDATE_SELECTION
    this.payload = { id, status, entry }
  }
}

export class UpdateParams {
  readonly type: typeof UPDATE_PARAMS
  readonly payload: {
    options: ScrollerOptions
    offset: number
    scrollElRef?: React.RefObject<Element>
  }

  constructor(params: {
    options: ScrollerOptions
    offset: number
    scrollElRef?: React.RefObject<Element>
  }) {
    this.type = UPDATE_PARAMS
    this.payload = params
  }
}

type Actions = UpdateSelection | UpdateParams

export type SelectionStrategy = (
  id: ScrollerId,
  status: ScrollerState['status'],
  options: ScrollerOptions,
  value?: string
) => boolean

export function scrollerReducer(
  state: ScrollerState,
  action: Actions
): ScrollerState {
  switch (action.type) {
    case UPDATE_SELECTION: {
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.id]: action.payload.status
        },
        entries: {
          [action.payload.id]: action.payload.entry
        }
      }
    }
    case UPDATE_PARAMS: {
      const optionStringLookup = generateOptionStringLookup(
        action.payload.options
      )
      return {
        ...state,
        offset: action.payload.offset,
        scrollElRef: action.payload.scrollElRef,
        options: Object.keys(optionStringLookup),
        optionStringLookup
      }
    }
    default:
      return state
  }
}

export interface ScrollerProviderProps {
  options?: ScrollerState['options']
  offset?: number
  animate?: boolean
  scrollElRef?: React.RefObject<Element>
  disableInitialScrollRestore?: boolean
}

/**
 * Wrap this provider around the menu and page elements
 * you wish to connect and scroll between
 */
export const ScrollerProvider: React.FC<
  React.PropsWithChildren<ScrollerProviderProps>
> = ({
  children,
  options = [],
  offset = TOP_OFFSET,
  animate = true,
  scrollElRef,
  disableInitialScrollRestore
}) => {
  const optionStringLookup = generateOptionStringLookup(options)

  const [state, dispatch] = useReducer(scrollerReducer, {
    options: Object.keys(optionStringLookup),
    optionStringLookup,
    offset,
    scrollElRef,
    status: {},
    entries: {}
  })

  useScrollRestorationForInitialId(
    state,
    offset,
    animate,
    scrollElRef?.current,
    disableInitialScrollRestore
  )

  React.useEffect(() => {
    if (offset || scrollElRef || options) {
      dispatch(
        new UpdateParams({
          offset,
          scrollElRef,
          options
        })
      )
    }
  }, [offset, scrollElRef, options])

  return (
    <ScrollerStateContext.Provider value={state}>
      <ScrollerDispatchContext.Provider value={dispatch}>
        {children}
      </ScrollerDispatchContext.Provider>
    </ScrollerStateContext.Provider>
  )
}

/**
 * selection strategy calculate the selection this can
 * be overridden to allow for different strategies.
 */
export const defaultSelectionStrategy: SelectionStrategy = (
  id: ScrollerId,
  statusState: ScrollerState['status'],
  options: ScrollerOptions,
  value?: string
): boolean => {
  const selection = options.find((opt) => statusState[opt])
  const selectedValue = selection ? selection === id : false
  if (selectedValue) {
    return selectedValue
  }

  return value === id
}

/**
 * Allows you access to the raw state. useful for more advanced use-cases
 * where you require full control.
 */
export const useScrollerRawState = () => {
  const context = useContext(ScrollerStateContext)
  if (isUndefined(context)) {
    throw new Error('useScrollerState must be used within a ScrollerProvider')
  }
  return context
}

/**
 * useContext based hook to supply top level useReducer state.
 * it derives the selection state from a status hashMap and returns
 * it to the user.
 */
export const useScrollerState = (
  id: ScrollerId,
  selectionStrategy: SelectionStrategy = defaultSelectionStrategy
): { selected: boolean } => {
  const { status, options } = useScrollerRawState()
  const { value: currentValue } = useCurrentScrollerValue()
  const firstNavItem = options[0]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledReplaceState = useCallback(
    throttle((id) => {
      window.history.replaceState(
        window.history.state,
        null!,
        // don't add a hash to the url for the first item - it's not needed (top of the page is the default)
        // and it can cause problems when we try to restore it
        `#${id === firstNavItem ? '' : id}`
      )
    }, 1000),
    [id, firstNavItem]
  )

  const derivedState = useMemo(() => {
    return {
      selected: selectionStrategy(id, status, options, currentValue)
    }
  }, [currentValue, id, options, selectionStrategy, status])

  useEffect(() => {
    // If the id is selected push it to the location bar
    if (derivedState.selected) {
      throttledReplaceState(id)
    }
  }, [derivedState.selected, id, throttledReplaceState])

  return derivedState
}

/**
 * useContext based hook to supply top level useReducer dispatch
 */
export const useScrollerDispatch = (): ContextType<
  typeof ScrollerDispatchContext
> => {
  const context = useContext(ScrollerDispatchContext)
  if (context === undefined) {
    throw new Error('useScrollerState must be used within a ScrollerProvider')
  }
  return context
}

/**
 * Convert the current URL hash back to its string ID
 * @returns the id represented by the URL hash
 */
export const hashToId = (): string =>
  decodeURIComponent(window.location.hash.replace('#', ''))

/**
 * Scrolls to the specified anchor element if it exists in the DOM
 * @param id - the id of the anchor to scroll to
 * @param offet - the pixel offset above the target element
 * @param animate - true for smooth scroll, false for no animation
 */
export const scrollToAnchor = (
  id: string,
  offset: number = TOP_OFFSET,
  animate: boolean = true,
  scrollEl?: Element
): void => {
  const anchorElement = document.getElementById(id)
  if (anchorElement) {
    scrollToPosition(
      anchorElement.getBoundingClientRect().top,
      offset,
      animate,
      scrollEl
    )
  }
}

/**
 * Accepts a anchor tag id locates it in the DOM and scrolls
 * the window to that position.
 */
export const useScrollToAnchorCallback = ({
  href,
  offset,
  animate
}: {
  href: string
  offset?: number
  animate?: boolean
}): (() => void) => {
  const { scrollElRef } = useScrollerRawState()
  const scrollEl = scrollElRef?.current || undefined
  return useCallback(() => {
    scrollToAnchor(href, offset, animate, scrollEl)
  }, [animate, href, offset, scrollEl])
}

/**
 * Custom logic do determine if entry is visible in the scroller area
 */
const getIsEntryInView = (
  entry: IntersectionObserverEntry | undefined,
  inView: boolean
): boolean => {
  if (!entry?.rootBounds || !inView) {
    return inView
  }

  // The threshold determines whether x% of the entire panel is viewable in the intersection window
  // But we consider the entry to be "in view" if 50% of entry.rootBounds (the intersection window) is _covered_ by the scroll panel
  // So even if only 10% of the panel is visible, if that 10% covers 50% of the intersection window, we are "in view"
  if (entry.intersectionRect.height / entry.rootBounds.height >= 0.5) {
    return true
  }
  // if half of the panel is in view we also consider the panel to be "in view"
  // (even if it doesn't cover 50% of the intersection window)
  if (entry.intersectionRatio >= 0.5) {
    return true
  }
  // otherwise it's not in view
  return false
}

/**
 * This hook will dispatch a select action and update
 * the state when ever a item fully intersections with the
 * screen.
 */
export const useSelectElementInView = (
  id: string,
  options: Parameters<typeof useInView>[0] = {
    threshold: [0.8]
  }
): {
  ref: InViewHookResponse['ref']
  inView: InViewHookResponse['inView']
  entry: InViewHookResponse['entry']
} => {
  const dispatch = useScrollerDispatch()
  const { offset } = useScrollerRawState()
  const [ref, inView, entry] = useInView({
    // offset * -1 is needed because sometimes offset is negative, so we can't just do `-${offset}...`
    // it isn't clear why offset is sometimes negative in some cases (like FocusView shrinking into the mobile breakpoint)
    // TODO: investigate this more and see if maybe offset is being calculated incorrectly in some cases
    rootMargin: `${offset * -1}px 0px 0px 0px`,
    ...options
  })
  useLayoutEffect(() => {
    dispatch(
      new UpdateSelection(
        sanitizeScrollerId(id),
        getIsEntryInView(entry, inView),
        entry
      )
    )
  }, [dispatch, entry, id, inView])
  return { ref, inView, entry }
}

export function useCurrentScrollerValue(): {
  value: ReturnType<typeof getDisplayValue>
  index: number
} {
  const { status, options } = useScrollerRawState()
  const [activeValue] = Object.entries(status).find(([, value]) => value) ?? []
  const previousValue = usePreviousValue(activeValue)

  return {
    value: getDisplayValue({ activeValue, previousValue, options }),
    index: options.findIndex((i) => activeValue === i)
  }
}

// When the activeValue cannot compute a section too wide in the viewport and returns an empty value
// a fallback of the previous value is used in this case. Also use the 1st option on initial load if the sections
// are too far away
function getDisplayValue({
  activeValue,
  previousValue,
  options
}: {
  activeValue: string | undefined
  previousValue: string | undefined
  options: ScrollerOptions
}): string {
  if (activeValue) {
    return activeValue
  } else if (previousValue) {
    return previousValue
  }

  return options[0]
}

function usePreviousValue(value: string | undefined): string | undefined {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [ref, value])
  return ref.current
}

function generateOptionStringLookup(options: ScrollerOptions) {
  return options.reduce(
    (lookup, option) => ({
      ...lookup,
      [sanitizeScrollerId(option)]: option
    }),
    {}
  )
}
