import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const CreatingIllustration = React.forwardRef(
  function CreatingIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '400'}
              height={resizeToContainer ? '100%' : '193'}
              viewBox='0 0 400 193'
              fill='none'
              className='inline-block'
            >
              <path
                d='M92.932 122.448v12.919M87.89 135.367c-4.274-17.189-21.59-10.51-21.59-10.51 0 12.919 8.658 14.123 8.658 14.123'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path
                d='M90.63 189.343c15.131 0 27.397-12.254 27.397-27.371 0-15.117-12.266-27.371-27.397-27.371-15.13 0-27.397 12.254-27.397 27.371 0 15.117 12.266 27.371 27.397 27.371z'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path
                d='M262.356 168.213c7.123 12.59 20.603 21.021 36.603 21.021 22.575 0 40.767-18.832 40.767-42.043h-61.37M301.808 139.856l7.781-6.569M301.041 125.294l-5.15-8.758M306.959 127.594l7.233-6.131M331.288 140.622l-1.315-10.072M286.356 139.856l-2.959-4.27M290.849 126.937l7.233 7.226M322.63 137.885l-5.698-8.43'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path d='M0 190h400' stroke='silver' strokeWidth={4} />
              <path
                d='M129.644 127.265h155.178'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M281.644 80.734l-14.247 26.058M300.712 34.97l-21.041 38.538c-1.424 2.628-.438 5.912 2.192 7.445 2.63 1.423 5.918.438 7.452-2.19l21.041-38.538c1.425-2.628.439-5.912-2.192-7.445-2.739-1.533-6.027-.547-7.452 2.19z'
                stroke='silver'
                strokeWidth={4}
              />
              <path
                d='M128.548 37.16l-16.767 16.203'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M93.37 32.89l15.342-14.781c1.973-1.97 5.699-1.095 7.671 1.752l16.767 23.977c1.535 2.19 1.425 4.708-.109 6.24l-8.767 8.43c-1.535 1.424-4.165 1.424-6.247-.218L94.685 40.772c-2.63-2.19-3.288-6.021-1.315-7.883z'
                stroke='silver'
                strokeWidth={4}
              />
              <path
                d='M133.917 71.428a.986.986 0 10.001-1.973.986.986 0 00-.001 1.973zM162.082 105.149a.985.985 0 10.002-1.97.985.985 0 00-.002 1.97zM142.027 95.296a.985.985 0 10.001-1.971.985.985 0 00-.001 1.97zM151.452 78.654a.986.986 0 100-1.972.986.986 0 000 1.972z'
                fill='#E5E5E5'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M220.712 40.115c4.298 0 7.781-3.48 7.781-7.773 0-4.293-3.483-7.773-7.781-7.773-4.297 0-7.78 3.48-7.78 7.773 0 4.293 3.483 7.773 7.78 7.773zM232.767 17.233s-8.109.219-10.082 7.555'
                stroke='silver'
                strokeWidth={4}
              />
              <path
                d='M259.507 41.758s-.329-18.941 25.096-35.911M273.096 15.81s4.493 1.642 8.438.657M267.178 23.802s3.288 2.3 8.439 1.095M260.822 34.75s5.479 1.752 8.657.986M275.068 12.635s-1.643-3.832.548-9.635M266.74 19.86s-4.274-3.94-2.301-10.51M262.027 31.247s-4.931-2.3-4.054-7.883'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M171.068 26.32l13.918 49.706M165.151 36.83l10.63 5.913M184 40.444l-5.918 10.62M166.466 9.679c-4.274 4.379-7.343 9.744-8.767 15.656-1.534 5.912-1.315 12.153.329 17.955 1.534 5.913 4.712 11.277 8.986 15.547 4.383 4.27 9.753 7.336 15.671 8.759 4.274-4.38 7.343-9.744 8.767-15.656 1.535-5.912 1.315-12.153-.328-17.956-1.535-5.912-4.713-11.277-8.987-15.547-4.383-4.16-9.753-7.226-15.671-8.758z'
                stroke='#FF4C00'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M238.247 93.106l-17.096-17.08M214.576 92.887l22.575-.876.986-22.663'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M225.754 105.587l29.699-29.67c-9.425-9.416-24.767-9.416-34.192 0-8.11 8.102-9.315 20.583-3.397 29.889'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M224.767 106.573l39.891-39.853c-14.576-14.561-38.247-14.561-52.822 0-10.85 10.84-13.589 26.496-8.439 39.853'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M128.438 127.265h155.068'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M287.343 106.573h-162.85c0 45.655 36.055 82.661 82.74 82.661 44.274 0 80.11-37.006 80.11-82.661z'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
