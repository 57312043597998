import { KeyboardEvent } from 'react'

export function normalizeEventKey(event: KeyboardEvent): string {
  const { key, keyCode } = event // eslint-disable-line deprecation/deprecation

  const isArrowKey =
    keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0

  const eventKey = isArrowKey ? `Arrow${key}` : key

  return eventKey
}
