import { useQuery } from '@apollo/client'
import { Layout } from '@local/layout'
import { ServerErrorPage } from '@local/depot-error-pages/ServerErrorPage'
import {
  PageTargetNavLayout,
  ScrollPanel
} from '@toasttab/buffet-pui-config-templates'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useMemo } from 'react'
import { ClientSecretProvider } from '../../contexts'
import { GET_CREDENTIALS } from '../../graphql'
import { Credentials } from '../../types'
import { CredentialsSection } from '../CredentialsSection'
import { GreyTextbox } from '../GreyTextbox'
import { ScopesSection } from '../ScopesSection'
import {
  CredentialsSections,
  DYNAMIC_ENDPOINT_SUBTITLE,
  DYNAMIC_ENDPOINT_URL,
  SCOPE_SUBTITLE,
  getActiveEnvFromLabel
} from './constants'
import { buildDepotRequestContext } from '@local/environment-switcher-utils'
import { useBanquetProps } from 'banquet-runtime-modules'

export const CredentialsPage = () => {
  const env = getCurrentEnvironment() as string
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()

  const {
    data: getCredentialsData,
    loading: getCredentialsDataLoading,
    error: getCredentialsDataError,
    refetch
  } = useQuery<{ getCredentials: Credentials }>(GET_CREDENTIALS, {
    context: buildDepotRequestContext(partner, mode ?? env),
    refetchWritePolicy: 'overwrite'
  })
  refetch({ mode })

  const credentials = useMemo(
    () => getCredentialsData?.getCredentials,
    [getCredentialsData?.getCredentials]
  )

  if (getCredentialsDataError) {
    return (
      <Layout
        title='Credentials'
        subTitle={partner.name}
        testId='credentials-page'
      >
        <ServerErrorPage />
      </Layout>
    )
  }

  return (
    <Layout
      title='Credentials'
      subTitle={partner.name}
      testId='credentials-page'
    >
      <>
        {getCredentialsDataLoading && (
          <div className='flex flex-row justify-center w-full h-screen'>
            <MerryGoRound
              className='pin-center'
              size='sm'
              testId='credentials-loader'
            />
          </div>
        )}
        {credentials && (
          <PageTargetNavLayout sections={Object.values(CredentialsSections)}>
            <ScrollPanel
              panelId={CredentialsSections.Credentials}
              title={CredentialsSections.Credentials}
            >
              <ClientSecretProvider clientId={credentials.clientId}>
                <CredentialsSection credentials={credentials} />
              </ClientSecretProvider>
            </ScrollPanel>

            {credentials.scopes && (
              <ScrollPanel
                panelId={CredentialsSections.Scopes}
                title={CredentialsSections.Scopes}
                subtitle={SCOPE_SUBTITLE}
              >
                <ScopesSection scopes={credentials.scopes} />
              </ScrollPanel>
            )}
            <ScrollPanel
              panelId={CredentialsSections.Endpoints}
              title={CredentialsSections.Endpoints}
              subtitle={
                DYNAMIC_ENDPOINT_SUBTITLE[getActiveEnvFromLabel(mode ?? env)]
              }
            >
              <GreyTextbox label='URL'>
                <div className='flex flex-col justify-center'>
                  {DYNAMIC_ENDPOINT_URL[getActiveEnvFromLabel(mode ?? env)]}
                </div>
              </GreyTextbox>
            </ScrollPanel>
          </PageTargetNavLayout>
        )}
      </>
    </Layout>
  )
}
