import { useMutation } from '@apollo/client'
import { captureException, withScope } from '@sentry/browser'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { track } from '@toasttab/use-heap'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { GET_LAST_ROTATE_INFO, ROTATE_CLIENT_SECRET } from '../graphql'
import { ClientSecret } from '../types'
import { buildDepotRequestContext } from '@local/environment-switcher-utils'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'

export const useRotateSecret = (
  clientId: string,
  setHasConfirmedRotation: Dispatch<SetStateAction<boolean>>
) => {
  const env = getCurrentEnvironment()
  const { mode } = useBanquetProps()
  const { partner } = useDepotBanquetProps()

  const [timeStart, setTimeStart] = useState<number | undefined>(undefined)

  const getTrackTimeParams = useCallback(() => {
    if (timeStart) return { 'execute-time-ms': Date.now() - timeStart }
    return {}
  }, [timeStart])

  const [
    rotateClientSecret,
    { loading, error: rotateClientSecretError, data }
  ] = useMutation<{
    rotateClientSecretV2: ClientSecret
  }>(ROTATE_CLIENT_SECRET, {
    context: buildDepotRequestContext(partner, mode ?? env),
    variables: { clientId },
    // skip save data in cache
    fetchPolicy: 'no-cache',
    // overwrite existing getLastRotateInfo data
    update: (cache, { data: newClientSecretData }) => {
      cache.writeQuery({
        query: GET_LAST_ROTATE_INFO,
        variables: { clientId },
        data: {
          getLastRotateInfo:
            newClientSecretData?.rotateClientSecretV2?.rotateInfo
        }
      })
    },
    onCompleted: () => {
      track('TPC_DEPOT_USER_SECRET_ROTATE_SUCCESSFUL', getTrackTimeParams())
      setHasConfirmedRotation(true)
    },
    onError: (error) => {
      track('TPC_DEPOT_USER_SECRET_ROTATE_FAILED', {
        ...getTrackTimeParams(),
        error: error.message
      })
      withScope((scope) => {
        scope.setTag('toast_component', 'useRotateSecret')
        scope.setTag('toast_function', 'useQuery:ROTATE_CLIENT_SECRET')
        scope.setLevel('warning')
        captureException(error)
      })
    }
  })

  const onConfirmRotateSecret = useCallback(() => {
    track('TPC_DEPOT_USER_SECRET_ROTATE_REQUESTED')
    setTimeStart(Date.now())
    rotateClientSecret()
  }, [rotateClientSecret])

  return {
    loading,
    onConfirmRotateSecret,
    clientSecret: data?.rotateClientSecretV2,
    rotateClientSecretError
  }
}
