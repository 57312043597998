import * as React from 'react'
import cx from 'classnames'

export interface PageTargetNavGroupTitleProps
  extends React.HTMLAttributes<HTMLElement> {
  className?: string
  testId?: string
}

export const PageTargetNavGroupTitle: React.FC<
  React.PropsWithChildren<PageTargetNavGroupTitleProps>
> = ({ testId = 'PageTargetNavGroupTitle', className, children, ...props }) => (
  <h3
    data-testid={testId}
    className={cx('type-default text-default font-semibold mb-2', className)}
    {...props}
  >
    {children}
  </h3>
)
