import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const PrepRecipeIllustration = React.forwardRef(
  function PrepRecipeIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '401'}
              height={resizeToContainer ? '100%' : '208'}
              viewBox='0 0 401 208'
              fill='none'
              className='inline-block'
            >
              <path
                d='M101.513 146.591c-4.684-18.788-23.659-11.488-23.659-11.488 0 14.122 9.488 15.438 9.488 15.438'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path
                d='M125.744 154.517a30.013 30.013 0 00-15.016-8.119 30.062 30.062 0 00-17.025 1.373A29.982 29.982 0 0080.19 158.19a29.857 29.857 0 00-1.526 32.627 29.96 29.96 0 0012.483 11.629 30.066 30.066 0 0016.823 2.948 30.03 30.03 0 0015.708-6.689M104.845 132.469v14.121'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinejoin='round'
              />
              <path
                d='M.517 205.591h400M319.324 86.996l-15.614 28.482M340.224 36.972l-23.058 42.125a5.987 5.987 0 00-.48 4.563 6.003 6.003 0 002.891 3.568 6.032 6.032 0 004.574.49 6.016 6.016 0 003.584-2.876l23.058-42.125a6.069 6.069 0 00.454-4.555 6.089 6.089 0 00-2.856-3.583 5.86 5.86 0 00-8.167 2.393v0zM252.55 42.597a8.536 8.536 0 004.731-1.436 8.502 8.502 0 003.133-3.815 8.469 8.469 0 00.482-4.909 8.498 8.498 0 00-2.333-4.348 8.528 8.528 0 00-9.283-1.838 8.517 8.517 0 00-3.822 3.13 8.478 8.478 0 001.064 10.729 8.536 8.536 0 006.028 2.487zM265.761 17.585s-8.887.24-11.049 8.257'
                stroke='silver'
                strokeWidth={4}
              />
              <path
                d='M295.064 44.391s-.361-20.703 27.501-39.253M309.956 16.03s4.924 1.796 9.248.719M303.471 24.766a12.107 12.107 0 009.248 1.197M296.506 36.732s6.005 1.916 9.487 1.078M312.116 12.56s-1.802-4.19.6-10.532M302.993 20.457a11.383 11.383 0 01-2.522-11.489'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M297.823 32.903a8.221 8.221 0 01-3.632-3.585 8.188 8.188 0 01-.811-5.032'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M198.146 27.518l15.252 54.332M191.66 39.006l11.649 6.462M212.319 42.956l-6.485 11.608M189.556 58.179a38.483 38.483 0 004.146 4.881 37.1 37.1 0 0017.173 9.574 36.894 36.894 0 009.608-17.113 39.13 39.13 0 00-.359-19.626 39.2 39.2 0 00-9.849-16.994 39.347 39.347 0 00-17.174-9.574 37.09 37.09 0 00-9.23 15.683'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M271.765 100.52L253.03 81.85M245.826 100.279l24.739-.957 1.081-24.772'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M258.076 114.162l32.547-32.432a26.513 26.513 0 00-17.226-7.702 26.528 26.528 0 00-17.992 5.697 26.401 26.401 0 00-9.619 16.202 26.368 26.368 0 003.644 18.475'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M256.991 115.238l43.716-43.56a40.977 40.977 0 00-24.681-11.736 41.019 41.019 0 00-26.584 6.382 40.842 40.842 0 00-16.623 21.653 40.726 40.726 0 00.754 27.261'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M213.398 137.858h107.965'
                stroke='#E5E5E5'
                strokeWidth={4}
                strokeLinecap='square'
                strokeLinejoin='round'
              />
              <path
                d='M216.533 203.179a93.683 93.683 0 0021.242 2.412c48.52 0 87.791-40.449 87.791-90.353h-121.12'
                stroke='silver'
                strokeWidth={4}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M187.366 74.489h2.192v-42.63a6.552 6.552 0 00-4.059-6.058 6.59 6.59 0 00-2.516-.5h-24.11a6.59 6.59 0 00-4.649 1.921 6.552 6.552 0 00-1.926 4.637v42.63h35.068zM187.367 74.489h1.513l.536 1.411 22.033 58.017a89.414 89.414 0 015.809 29.958l.704 35.091a6.551 6.551 0 01-1.879 4.722 6.58 6.58 0 01-4.696 1.968h-80.918a6.594 6.594 0 01-4.696-1.968 6.546 6.546 0 01-1.878-4.722l.703-35.091a89.44 89.44 0 015.81-29.958L152.441 75.9l.535-1.411h34.391v0zM153.394 43.883h35.068'
                stroke='#FF4C00'
                strokeWidth={4}
              />
              <path
                d='M199.421 163.755c0 9.38-4.56 15.097-10.372 18.575a36.973 36.973 0 01-36.241 0c-5.813-3.478-10.373-9.195-10.373-18.575 0-9.743 4.881-24.309 11.196-36.575a84.998 84.998 0 019.788-15.323 26.017 26.017 0 014.481-4.355 4.13 4.13 0 013.028-1.314 4.148 4.148 0 013.028 1.314 26.017 26.017 0 014.481 4.355 85.003 85.003 0 019.789 15.323c6.314 12.266 11.195 26.832 11.195 36.575z'
                stroke='#FF4C00'
                strokeWidth={4}
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
