import * as React from 'react'
import { useAnchorElementProps } from './propsSpreader'

export interface ScrollToContainerProps {
  id: Parameters<typeof useAnchorElementProps>[0]
  options: Parameters<typeof useAnchorElementProps>[1]
}

/**
 * Wrap this around an element you wish to scroll to.
 */
export const ScrollToContainer: React.FC<
  React.PropsWithChildren<ScrollToContainerProps>
> = ({ children, id, options }) => {
  const { spreadProps } = useAnchorElementProps<'div'>(id, options)
  return <div {...spreadProps()}>{children}</div>
}
