import * as React from 'react'
import cx from 'classnames'
import {
  PageTargetNavProvider,
  usePageTargetNav
} from '../PageTargetNavContext'

export interface PageTargetNavProps extends React.HTMLAttributes<HTMLElement> {
  className?: string
  /**
   * The class name for the overall container of the `MobilePageTargetNav`
   */
  containerClassName?: string
}

export const PageTargetNav: React.FC<
  React.PropsWithChildren<PageTargetNavProps>
> = ({ className, containerClassName, ...props }) => {
  const { focusedIndex, setFocusedIndex, count, domContext, onKeyDown } =
    usePageTargetNav()
  return (
    <PageTargetNavProvider
      value={{
        domContext,
        focusedIndex,
        setFocusedIndex,
        count,
        onKeyDown
      }}
    >
      <nav
        role='menu'
        className={cx('flex flex-col', containerClassName, className)}
        {...props}
      >
        {props.children}
      </nav>
    </PageTargetNavProvider>
  )
}
