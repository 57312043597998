import * as React from 'react'
import cx from 'classnames'
import { ExpandMoreIcon, ArrowUpIcon } from '@toasttab/buffet-pui-icons'
import { useIntersection } from 'react-use'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { t, loadStrings } from './defaultStrings'

export interface BaseMobileNavButtonProps extends TestIdentifiable {
  subTitle?: string
  testId?: string
  hasScrollReturn?: boolean
  onClick: () => void
  triggerRef: React.RefObject<HTMLElement>
  scrollElRef?: React.RefObject<Element>
}

export const BaseMobileNavButton = ({
  onClick,
  triggerRef,
  hasScrollReturn,
  children,
  testId = 'mobile-nav-expand',
  subTitle = '',
  scrollElRef
}: React.PropsWithChildren<BaseMobileNavButtonProps>) => {
  const intersection = useIntersection(triggerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  })
  const isIntersecting = !(intersection && intersection.intersectionRatio < 1)
  const showScrollReturn = !isIntersecting && hasScrollReturn
  loadStrings()

  return (
    <div ref={triggerRef as React.RefObject<HTMLDivElement>}>
      <div
        className={cx(
          'flex-row items-stretch bg-white w-full type-default text-default group outline-none flex justify-between',
          {
            'fixed top-0 left-0 right-0 z-10 shadow-md': !isIntersecting, // elevation: this button acts as a sticky header which uses z-10 according to our guidelines
            'border border-gray-25 rounded-button h-16': isIntersecting
          }
        )}
      >
        <button
          className={cx(
            'flex flex-row justify-between items-center flex-grow',
            'outline-none',
            {
              // Focus states are edge cases on mobile, but
              // we should account for the occasional time that desktop
              // devices have small screen widths for completeness
              'focus-visible:shadow-focus rounded-button': isIntersecting,
              'focus-visible:shadow-focus-inset': !isIntersecting
            }
          )}
          data-testid={testId}
          onClick={() => onClick()}
        >
          <div className='flex flex-col justify-center items-start px-3'>
            {subTitle && (
              <span className='type-overline text-secondary'>{subTitle}</span>
            )}
            <span className='type-default text-default'>{children}</span>
          </div>
          <ExpandMoreIcon
            className='mr-3 text-secondary'
            aria-label={t('open-navigation')}
          />
        </button>
        {showScrollReturn && (
          <button
            className={cx(
              'flex flex-none items-center justify-center w-16 h-16 bg-brand-50',
              'focus-visible:shadow-focus-inset outline-none'
            )}
            onClick={() => {
              const scrollableEl = scrollElRef?.current || window
              scrollableEl.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
            }}
            aria-label={t('scroll-up')}
            type='button'
          >
            <ArrowUpIcon
              size='md'
              className='text-white'
              accessibility='decorative'
            />
          </button>
        )}
      </div>
      {!isIntersecting && <div className='w-full h-16' />}
    </div>
  )
}
